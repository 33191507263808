import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors, actions } from '../store';
import { RouteComponentProps } from "react-router";
import { Billboard } from '../store/billboards/types';
import GoogleMapReact from "google-map-react"
import MapMarker from "./MapMarker"
import BillBoardDetail from "../pages/BillBoardDetail"
import { getTranslate, TranslateFunction } from "react-localize-redux";
import { IPropertySet } from '../WebServerApi/webServerTypes';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';


type DispatchProps = {
    updateBillboard: (key:string, propertySet:IPropertySet) => void
};

type Props = RouteComponentProps<{}> & DispatchProps & {
    billboards: Billboard[],
    translateFunc: TranslateFunction,
    licenceKey: string | null,
};

type State = {
    showFilter: boolean,
    modalToShow: number,
}

const getMapBounds = (map: any, maps: any, billboards: Billboard[]) => {
    const bounds = new maps.LatLngBounds();

    billboards.forEach((billboard) => {
        bounds.extend(new maps.LatLng(
            billboard.location.Latitude,
            billboard.location.Longitude,
        ));
    });
    return bounds;
};
const bindResizeListener = (map: any, maps: any, bounds: google.maps.LatLngBounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
};

const apiIsLoaded = (map: any, maps: any, billboards: Billboard[]) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, billboards);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
};

class Map extends Component<Props, State> {
    modalEle: React.RefObject<BillBoardDetail>;
    map: any;
    maps: any;
    state = {
        showFilter: false,
        modalToShow: 0
    }
    constructor(props: Props) {
        super(props);
        this.modalEle = React.createRef();
    }

    presentFilter = () => {
        let oldFilter = this.state.showFilter;
        this.setState(() => ({
            showFilter: !oldFilter
        }));
    }
    clearFilter = () => {
        //this.props.setBillboardAreaFilters([]);
        //this.props.setBillboardStatusFilters([]);
        //this.props.setBillboardFormatFilters([]);
        //this.setState(() => ({
        //  showFilter: false
        //}));
    }
    navigateToBillboard = (billboardId: number) => () => {
        if (this.modalEle.current) {
            this.modalEle.current.setBillboard(billboardId);
            this.modalEle.current.open();
        }
    }
    

    componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
        if (this.props.billboards.length !== prevProps.billboards.length) {
            console.log("map resize");
            const bounds = getMapBounds(this.map, this.maps, this.props.billboards);
            // Fit map to bounds
            this.map.fitBounds(bounds);
        }
    }
    
    updateBillboard = (propertySet:IPropertySet) => {
        this.props.updateBillboard(this.props.licenceKey!, propertySet)
    }

    render() {
        let lat: number = 55.752256;
        let lng: number = 37.619345;
        
        return (
            <div className="map-page">
                <div style={{ height: '95vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAmAwkv8-x2I--ne-NtA3C_4E_-xLCsFJs" }}
                        defaultCenter={{ lat, lng }}
                        defaultZoom={10}
                        onGoogleApiLoaded={({ map, maps }) => {
                            this.map = map;
                            this.maps = maps;
                            apiIsLoaded(map, maps, this.props.billboards);
                        }}>
                        {this.props.billboards.map((billboard: Billboard, billboardIndex: number) => (
                        <MapMarker lat={billboard.location.Latitude} lng={billboard.location.Longitude} status='свободна' text={billboard.GID} key={billboard.id} onClick={this.navigateToBillboard(billboard.id)}></MapMarker>
                    ))}
                    </GoogleMapReact>
                    <BillBoardDetail
                     ref={this.modalEle} 
                     billboards={this.props.billboards} 
                     translate={this.props.translateFunc}
                     onUpdateBillboard={this.updateBillboard}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    billboards: selectors.billboards.allBillboards(state.billboards),
    translateFunc: getTranslate(state.localize),
    licenceKey: state.user.licenceKey,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
    updateBillboard: (key: string, propertySet:IPropertySet) => dispatch(actions.billboards.updateBillboard(key,propertySet)),
});

export default connect(
    mapStateToProps,mapDispatchToProps
)(Map);
