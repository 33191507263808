import { CustomerActions, CustomerActionTypes } from "./actions";
import { CustomerState } from './types';
import { Reducer } from "redux";

const defaultState: CustomerState = {
    filters: {
        name: '',
        phone: '',
        email: '',
        inn: '',
        isLegal: null,
        isVip: null,
        companyId: 0,
        dateFrom: null,
        dateTo: null,
        ordersFrom: null,
        ordersTo: null,
    },
    foundCustomers: [],
    isLoading: false,
    foundByInn : null

};

export const customerReducer: Reducer<CustomerState, CustomerActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case CustomerActionTypes.UPDATE_CUSTOMER_FILTERS:
            {
                return {
                    ...state,
                    filters: action.filters
                };
            }
        case CustomerActionTypes.SEARCH_CUSTOMERS:
            {
                return {
                    ...state,
                    foundCustomers: [],
                    isLoading: true
                };
            }
        case CustomerActionTypes.SEARCH_CUSTOMERS_ERROR:
            {
                return {
                    ...state,
                    foundCustomers: [],
                    isLoading: false
                };
            }
        case CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS:
            {
                return {
                    ...state,
                    foundCustomers: action.customers,
                    isLoading: false
                };
            }
        case CustomerActionTypes.SEARCH_INN:
            {
                return {
                    ...state,
                    foundByInn: null,
                    isLoading: true
                };
            }
        case CustomerActionTypes.SEARCH_INN_ERROR:
            {
                return {
                    ...state,
                    foundByInn: null,
                    isLoading: false
                };
            }
        case CustomerActionTypes.SEARCH_INN_SUCCESS:
            {
                return {
                    ...state,
                    foundByInn: action.company,
                    isLoading: false
                };
            }
        case CustomerActionTypes.SAVE_CUSTOMER:
            {
                return {
                    ...state,
                };
            }
        case CustomerActionTypes.SAVE_CUSTOMER_ERROR:
            {
                return {
                    ...state,
                };
            }
        case CustomerActionTypes.SAVE_CUSTOMER_SUCCESS:
            {
                var id = state.foundCustomers.findIndex(c => action.customer.Id === c.Id);
                var customers = [...state.foundCustomers];
                customers[id] = action.customer;
                return {
                    ...state,
                    foundCustomers: customers,
                    isLoading: false
                };
            }
        default:
            return state;
    }

};

export default customerReducer;