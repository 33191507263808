import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState,  actions } from '../store';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import "./Menu.scss"
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import { UserInfo } from "../store/user/types";
import { withLocalize, LocalizeContextProps, Translate } from "react-localize-redux";
import LanguageSelector from './LanguageSelector';

import Icon from '@material-ui/core/Icon';

//const routes = {
//    appPages: [
//        {
//            title: "Щиты",
//            links: [
//                { title: 'Карта', path: '/map', icon: 'map' },
//                { title: 'Список', path: '/billboardList', icon: 'list' }
//            ]
//        }
//    ]
//}

type DispatchProps= {
    logout: () => Promise<void>;
    togglePhone: () => void;
    toggleMenu: () => void;
}

type StateProps = {
    userInfo?: UserInfo;
    showFullMenu: boolean;
}
type Props = RouteComponentProps<{}> & DispatchProps & StateProps & LocalizeContextProps;


class Menu extends React.Component<Props> {

    toggleMenu() {
        this.props.toggleMenu();
    }

    renderlistItems(list: any[]) {
        return list.map((item) => (
            <li className="active" key={item.title}>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                        <i className="fas fa-ad"></i> {item.title}</a>
                    <ul className="collapse list-unstyled" id="homeSubmenu">
                        {item.links
                            .map((p: { title: string | number | undefined; path: string; icon: any; }) => (
                                <li key={p.path}>
                                    <Link to={p.path}>
                                        <i className={"fas fa-" + p.icon}></i> {p.title}</Link>
                                </li>
                            ))}
                    </ul>
                </li>
        ));
    }

    render() {
        return (
            <nav id="sidebar" className={`sidebar ${!this.props.showFullMenu ? "sidebar_small" : "sidebar_large"}`}>
                <div className="sidebar__header" onClick={this.toggleMenu.bind(this)}>
                    <div className="sidebar__logo" id="sidebarCollapse">
                        <span className="sidebar__logo-desctop">BlackBoard</span>
                        <span className="sidebar__logo-mobile">BB</span>
                    </div>
                    <div className="sidebar__burger"><Icon>menu</Icon></div>
                </div>
                <div className="sidebar-lang">
                    <LanguageSelector/>
                </div>
                {/* <div className="profile">
                    <div className="profile__wrap">
                        <span>m</span>
                        <img src="" alt=""/>
                    </div>
                    <div className="profile__group">
                        <div className="profile__login"><Icon>person</Icon>{this.props.userInfo ? this.props.userInfo.username : <Translate id='MenuProfile'/>}</div>
                        <a className="profile__logout" onClick={() => this.props.logout()}><Icon>exit_to_app</Icon> <span className="text"><Translate id='MenuLogout' /></span></a>
                    </div>
                </div> */}
                <ul className="menu-nav">
                    <li className="menu-nav__item" key='Карта'>
                        <Link to='/map'>
                            <Icon>map</Icon><span className="text"><Translate id='MenuBillboardMap'/></span>
                        </Link>
                    </li>
                    <li className="menu-nav__item" key='Список'>
                        <Link to='/billboardList'>
                            <Icon>view_list</Icon><span className="text"><Translate id='MenuBillboardList'/></span>
                        </Link>
                    </li>
                    <li className="menu-nav__item" key='Клиенты'>
                        <Link to='/customers'>
                            <Icon>people</Icon><span className="text"><Translate id='MenuCustomers'/></span>
                        </Link>
                    </li>
                </ul>
                <ul className="profile-nav">
                    <li className="profile-nav__item" key='Профиль'>
                        <a href="#profileSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <Icon>person</Icon><span className="text">{this.props.userInfo ? this.props.userInfo.username : <Translate id='MenuProfile'/>}</span>
                        </a>
                        <ul className="collapse list-unstyled" id="profileSubmenu">
                            <li key='logout'>
                                <a style={{ cursor: 'pointer' }} onClick={() => this.props.logout()}><Icon>exit_to_app</Icon> <span className="text"><Translate id='MenuLogout' /></span></a>
                            </li>
                        </ul>
                    </li>
                </ul>

                {/* <ul className="list-unstyled components">
                    <li className="active" key='Карта'>
                        <Link to='/map'>
                            <i className="fas fa-map"></i> <Translate id='MenuBillboardMap'/></Link>
                    </li>
                    <li className="active" key='Список'>
                        <Link to='/billboardList'>
                            <i className="fas fa-list"></i> <Translate id='MenuBillboardList'/></Link>
                    </li>
                    <li className="active" key='Клиенты'>
                        <Link to='/customers'>
                            <i className="fas fa-address-card"></i> <Translate id='MenuCustomers'/></Link>
                    </li>
                    <li className="active" key='Профиль'>
                        <a href="#profileSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <i className="fas fa-user-circle"></i> {this.props.userInfo ? this.props.userInfo.username : <Translate id='MenuProfile'/>}</a>
                        <ul className="collapse list-unstyled" id="profileSubmenu">
                            <li key='logout'>
                                <a style={{ cursor: 'pointer' }} onClick={() => this.props.logout()}><i className="fas fa-sign-out-alt"></i> <Translate id='MenuLogout' /></a>
                            </li>
                        </ul>
                    </li>
                </ul> */}
                {/* <ul>
                    <li key='call' className="bottom_button">
                        <a style={{ cursor: 'pointer' }} onClick={this.props.togglePhone}><i className="fas fa-phone"></i></a>
                    </li>
                </ul>            */}
                
            </nav>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    userInfo: state.user.userInfo,
    showFullMenu: state.app.showFullMenu
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    logout: () => dispatch(actions.user.logoutActionCreator()),
    togglePhone: () => dispatch(actions.app.changePhoneControlStateAction()),
    toggleMenu: () => dispatch(actions.app.changeMenuStateAction())
});

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu)));
