import React from 'react';
import './css/toast.css'

import { Sip } from '../sip/sip';

type Props = {
    closeToast?: () => void,
}

class IncomingCallToast extends React.Component<Props>{

    componentDidMount() {
        var self = this;
        Sip.OnSipChanged.subscribe(() => {
            if (this.props.closeToast && (!Sip.HasActiveCall || Sip.IsEstablished))
                this.props.closeToast();
            self.forceUpdate();
        });
    }

    acceptCall() {
        try {
            Sip.Answer();
            if (this.props.closeToast)
                this.props.closeToast();
        } catch (e) {
            console.log(e);
        }
    }

    refuseCall() {
        try {
            Sip.HangUp();
            if (this.props.closeToast)
                this.props.closeToast();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div style={{ background: '#080808', padding: 10, margin:0}} className="text-light input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">{Sip.PhoneNumber}</span>
                </div>
                <div className="input-group-append">
                    <a href='#' className='btn btn-outline-success' onClick={this.acceptCall.bind(this)}><img width="30" height="30" src='assets/img/call_accept.png'/></a>
                    <a href='#' className='btn btn-outline-danger' onClick={this.refuseCall.bind(this)}><img width="30" height="30" src='assets/img/call_refuse.png'/></a>
                </div>
            </div>
        );
    }
}

export function getIncomingCallControl(number: string) {
    return (<IncomingCallToast/>);
}