import { DefaultProperties } from "../store/billboards/types";

export class GeoMark {
    constructor(addressId: number,
        color: number,
        id: number,
        name: string,
        photoImage: Uint8Array,
        propertyValueSetId: number,
        radius: number,
        type: number,
        typeId: number,
        points: LatLong[],
        objectId: string,
        description: string) {
        this.AddressId = addressId;
        this.Color = color;
        this.Id = id;
        this.Name = name;
        this.PhotoImage = photoImage;
        this.PropertyValueSetId = propertyValueSetId;
        this.Radius = radius;
        this.Type = type;
        this.TypeId = typeId;
        this.Points = points;
        this.ObjectId = objectId;
        this.Description = description;
    }

    AddressId: number;
    Color: number;
    Id: number;
    Name: string;
    ObjectId: string;
    PhotoImage: Uint8Array;
    PropertyValueSetId: number;
    Radius: number;
    Type: number;
    TypeId: number;
    Points: LatLong[];
    Description: string;
}

export class LatLong {
    constructor(latitude: number, longitude: number) {
        this.Latitude = latitude;
        this.Longitude = longitude;
    }

    Latitude: number;
    Longitude: number;
}

export class PropertyValueSet {
    constructor(propertyValueSetId: number, propertyValues: PropertyValue[]) {
        this.PropertyValueSetId = propertyValueSetId;
        this.PropertyValues = propertyValues;
    }

    PropertyValueSetId: number;
    PropertyValues: PropertyValue[];
}

export class PropertyValue {
    constructor(isException: boolean, value: string, property: Property) {
        this.IsException = isException;
        this.Value = value;
        this.Property = property;
    }

    IsException: boolean;
    Value: string;
    Property: Property;
}

export class Property {
    constructor(propertyName: string, propertyNameLocal: string) {
        this.PropertyName = propertyName;
        this.PropertyNameLocal = propertyNameLocal;
    }

    PropertyName: string;
    PropertyNameLocal: string;
}

export interface IClientInfo {
    Id: number;
    Name: string;
    FirstName: string;
    SecondName: string;
    Patronymic: string;
    Phones: PhoneInfo[];
    Emails: EmailInfo[];
    IsLegalEntity: boolean;
    INN: string;
    IsVip: boolean;
    OrdersCount: number;
    RegistrationDate: Date | null;
}
export class ClientInfo {
    constructor(id: number,
        name: string,
        firstName: string,
        secondName: string,
        patronymic: string,
        phones: PhoneInfo[],
        emails: EmailInfo[],
        isLegalEntity: boolean,
        inn: string,
        isVip: boolean,
        ordersCount: number,
        registrationDate: Date|null) {
        this.Id = id;
        this.Name = name;
        this.FirstName = firstName;
        this.SecondName = secondName;
        this.Patronymic = patronymic;
        this.Phones = phones;
        this.Emails = emails;
        this.IsLegalEntity = isLegalEntity;
        this.INN = inn;
        this.IsVip = isVip;
        this.OrdersCount = ordersCount;
        this.RegistrationDate = registrationDate;
    }

    static fromInnData(data: any) {
        if (data == undefined)
            return undefined;
        return new ClientInfo(-1,
            data.name.short_with_opf,
            '',
            '',
            '',
            new Array<PhoneInfo>(),
            new Array<EmailInfo>(),
            true,
            data.inn,
            false,
            0,
            null);
    }

    getCopy(): ClientInfo {
        let phones = this.Phones.map((p: PhoneInfo) => Object.assign({}, p));
        let emails = this.Emails.map((p: EmailInfo) => Object.assign({}, p));
        return new ClientInfo(this.Id,
            this.Name,
            this.FirstName,
            this.SecondName,
            this.Patronymic,
            [...phones],
            [...emails],
            this.IsLegalEntity,
            this.INN,
            this.IsVip,
            this.OrdersCount,
            this.RegistrationDate);
    }

    Id: number;
    Name: string;
    FirstName: string;
    SecondName: string;
    Patronymic: string;
    Phones: PhoneInfo[];
    Emails: EmailInfo[];
    IsLegalEntity: boolean;
    INN: string;
    IsVip: boolean;
    OrdersCount: number;
    RegistrationDate: Date | null;
    get PhonesString() {
        return this.Phones.map(p => p.PhoneNumber).join(';');
    }
    get EmailsString() {
        return this.Emails.map(p => p.Email).join(';');
    }
}


export interface PhoneInfo {
    PhoneId: number;
    PhoneNumber: string;
}
export interface EmailInfo {
    EmailId: number;
    Email: string;
}


export class PropertyValuesArray {
    [index: string]: string;
}

export class Translation {
    constructor(key: string, language: string, text: string) {
        this.Key = key;
        this.Language = language;
        this.Text = text;
    }

    Key: string;
    Language: string;
    Text: string;
}

export interface CustomerWebFilter {
    Name: string;
    Phone: string;
    Email: string;
    INN: string;
    IsLegal: boolean | null;
    IsVip: boolean | null;
    CompanyId: number;
    OrderFrom: number | null;
    OrderTo: number | null;
    RegistrationDateFrom: Date | null;
    RegistrationDateTo: Date | null;
}

export interface IProperty{
    PropertyId: number;
    PropertyName:string;
    PropertyRequired:boolean;
    SelectedValueId:number;
    Values: {
        ValueId: number;
        Value: string;
    }[]
}

export interface IPropertySet{
    PropertySetId: number;
    PropertySetName: string;
    Properties: IProperty[];
}
export interface IPropertySet{
    PropertySetId: number;
    PropertySetName: string;
    Properties: IProperty[];
}

export class PropertiesHelper {

    static arePropertiesValid(set: PropertyValueSet): boolean {
        if (!set)
            return false;
        const importantProperties = Object.keys(DefaultProperties);
        for (let i = 0; i < importantProperties.length; i++) {
            if (!set.PropertyValues.find(pv => pv.Property.PropertyName === importantProperties[i])) {
                console.log(`${importantProperties[i]} not found`);
                return false;
            }
        }
        return true;
    }

    static getPropertyValue(set: PropertyValueSet, property: string) {
        const propertyValue = set.PropertyValues.find(pv => pv.Property.PropertyName === property);
        if (propertyValue)
            return propertyValue.Value;
        return undefined;
    }

    static getValuesArray(set: PropertyValueSet): PropertyValuesArray {
        const r = new PropertyValuesArray();
        set.PropertyValues.forEach(v => r[v.Property.PropertyName] = v.Value);
        return r;
    }
}