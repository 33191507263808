import React from "react";
import { connect } from 'react-redux';
import { getTranslate, Translate, TranslateFunction } from "react-localize-redux";
import { RootState } from '../store';
import { CallInfo } from "../store/sip/types";
import "./css/CallHistoryList.scss";
import Icon from '@material-ui/core/Icon';

type StateProps = {
    translate: TranslateFunction;
    calls: CallInfo[];
}

type Props = StateProps & {
    onClick: (call:CallInfo) => void
};

class CallHistoryList extends React.Component<Props> {

    private GetDateString(time: Date) {
        let today = new Date();
        if (time.getDate() === today.getDate()) {
            return time.toLocaleTimeString('ru-ru');
        } else {
            return time.toLocaleDateString('ru-ru');
        }
    }


    render() {
        if (!this.props.calls)
            return null;
        return (
            <div className="call-phone-scroll">
                <table className="call-list">
                    <thead>
                        <tr>
                            <th style={{ width: 25 }}><Icon>ring_volume</Icon></th>
                            <th style={{ width: 100 }}><Translate id='CallTime'/></th> 
                            <th style={{ width: 200, textAlign:'right' }}><Translate id='SipNumber' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.calls.map((call:CallInfo) => (
                            <tr key={'call_' + call.time} onClick={() => this.props.onClick(call)}>
                                <td style={{ width: 20 }}><Icon>{`${call.isIncoming ? 'phone_callback' : 'phone_forwarded'}`}</Icon></td>
                                <td style={{ width: 100, cursor: 'pointer' }}>{this.GetDateString(call.time)}</td>
                                <td style={{ width: 200, textAlign:'right', cursor: 'pointer' }}>{call.number}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* <table className="table-borderless table-sm">
                    <thead>
                        <tr>
                            <th style={{ width: 25 }}></th>
                            <th style={{ width: 95 }}><Translate id='CallTime'/></th>
                            <th style={{ width: 200, textAlign: 'right' }}><Translate id='SipNumber' /></th>
                        </tr>
                    </thead>
                </table>
                <div id="scrolltable">
                    <table className="table-borderless table-sm">
                        <tbody>
                            {this.props.calls.map((call:CallInfo) => (
                                <tr key={'call_' + call.time} style={{ cursor: 'pointer' }} onClick={() => this.props.onClick(call)}>
                                    <td style={{ width: 20 }}><i className={call.isIncoming ? "fas fa-chevron-circle-down" : "fas fa-chevron-circle-up"}></i></td>
                                    <td style={{ width: 100 }}>{this.GetDateString(call.time)}</td>
                                    <td style={{ width: 200, textAlign:'right' }}>{call.number}</td>
                                </tr>))}
                        </tbody>
                    </table>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    translate: getTranslate(state.localize),
    calls: state.sip.callsHistory
});
export default connect(mapStateToProps)(CallHistoryList);