import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from "react-localize-redux";
import AppStack from './pages/AppStack';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import store from './store';

toast.configure();
const App = () => (
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <Router>
                <Switch>
                    <Route path="/" component={AppStack} />
                </Switch>
            </Router>
        </LocalizeProvider>
    </Provider>
);


export default App;