import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { Modal, Container, Row, Col, ListGroup, Form, Button, ButtonGroup, InputGroup } from "react-bootstrap"
import { TranslateFunction, Translate, LocalizeContextProps, getTranslate } from "react-localize-redux";
import { ClientInfo, PhoneInfo } from "../WebServerApi/webServerTypes";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, Action } from "redux";
import { RootState, selectors, actions } from "../store";

type DispatchProps = {
    saveClient: (key:string, client:ClientInfo) => void
};

type Props = LocalizeContextProps & DispatchProps & {
    clients: ClientInfo[],
    translate: TranslateFunction,
    licenceKey: string | null,
};

type State = {
    showModal: boolean;
    clientId: number;
    originalName: string;
    clientInfo?: ClientInfo;
    clientInfoByInn?: ClientInfo,
    validated: boolean;
}

export class ClientDetail extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props);
        this.state = {
            showModal: false,
            clientId: 0,
            originalName: '',
            clientInfo: undefined,
            clientInfoByInn: undefined,
            validated: false
        }
    }

    close() {
        this.setState({ showModal: false });
    }

    open() {
        this.setState({ showModal: true });
    }

    public setClient(id: number) {
        let c = this.props.clients.find((b) => b.Id === id);
        this.setState({
            clientId: id,
            clientInfo: c,
            originalName: (c == undefined ? '' : c.Name),
            clientInfoByInn: undefined
        });
    }
    public setByInn(company: ClientInfo, id: number) {
        let c = this.props.clients.find((b) => b.Id === id);
        if (!c)
            c = company.getCopy();
        this.setState({
            clientId: id,
            clientInfo: c,
            originalName: (c == undefined ? '' : c.Name),
            clientInfoByInn: company
        });
    }

    onNameChanged(name: string) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Name = name;
        this.setState({clientInfo:clientInfo});
    }
    onFirstNameChanged(name: string) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.FirstName = name;
        this.setState({clientInfo:clientInfo});
    }
    onSecondNameChanged(name: string) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.SecondName = name;
        this.setState({clientInfo:clientInfo});
    }
    onPatronymicChanged(patronymic: string) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Patronymic = patronymic;
        this.setState({clientInfo:clientInfo});
    }
    onPhoneChanged(phone: string, i: number) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Phones[i].PhoneNumber = phone;
        this.setState({clientInfo:clientInfo});
    }
    onDeletePhone(phone: string, i: number) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Phones.splice(i,1);
        this.setState({clientInfo:clientInfo});
    }
    onAddNewPhone() {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Phones.push({PhoneId: -1, PhoneNumber:''});
        this.setState({clientInfo:clientInfo});
    }
    onEmailChanged(email: string, i: number) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Emails[i].Email = email;
        this.setState({clientInfo:clientInfo});
    }
    onDeleteEmail(email: string, i: number) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Emails.splice(i, 1);
        this.setState({ clientInfo: clientInfo });
    }
    onAddNewEmail() {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.Emails.push({ EmailId: -1, Email: '' });
        this.setState({ clientInfo: clientInfo });
    }
    onINNChanged(inn: string) {
        let clientInfo = this.state.clientInfo!.getCopy();
        clientInfo.INN = inn;
        this.setState({clientInfo:clientInfo});
    }
    onSaving(e: any) {
        let phonesValid: boolean = this.state.clientInfo != undefined && this.state.clientInfo.Phones.every(p => this.IsNumberValid(p.PhoneNumber));
        let emailsValid: boolean = this.state.clientInfo != undefined && this.state.clientInfo.Emails.every(p => this.IsEmailValid(p.Email));
        if (!phonesValid || !emailsValid) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        this.props.saveClient(this.props.licenceKey!, this.state.clientInfo!);
        this.close();
    }

    IsNumberValid(text:string) {
        let regExp = new RegExp('^\\+?[0-9]+$');
        return regExp.test(text);
    }
    IsEmailValid(text: string) {
        if (text === '' && !this.state.clientInfo!.IsLegalEntity)
            return true;
        let regExp = new RegExp('^\\S+@\\S+$');
        return regExp.test(text);
    }

    render() {
        if (!this.state.clientInfo)
            return null;
        let title: string = String(this.state.clientInfo.IsLegalEntity ? this.props.translate('ClientDetailCompany') : this.props.translate('ClientDetailClient'));
        return (
            <Modal show={this.state.showModal} onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title} #{this.state.clientInfo.Id} {this.state.clientInfo.Name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form noValidate validated={this.state.validated}>
                            {this.state.clientInfo.IsLegalEntity ? (
                                <Form.Group controlId="customerDetail.Name">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><Translate id='ClientDetailName' /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control value={this.state.clientInfo.Name} onChange={(e: any) => this.onNameChanged(e.target.value)} />
                                    </InputGroup>
                                    {this.state.clientInfoByInn && this.state.clientInfoByInn.Name !== this.state.clientInfo.Name ? (
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><Translate id='ClientDetailFoundName' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <InputGroup.Append>
                                                <Button variant="outline-success" onClick={() => this.onNameChanged(this.state.clientInfoByInn!.Name)}>{this.state.clientInfoByInn.Name}</Button>
                                            </InputGroup.Append>
                                        </InputGroup>) : null}
                                </Form.Group>) :
                                (
                                    <>
                                    <Form.Group controlId="customerDetail.FirstName">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><Translate id='ClientDetailName' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control value={this.state.clientInfo.FirstName} onChange={(e: any) => this.onFirstNameChanged(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="customerDetail.SecondName">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><Translate id='ClientDetailSecondName' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control value={this.state.clientInfo.SecondName} onChange={(e: any) => this.onSecondNameChanged(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="customerDetail.Patronymic">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><Translate id='ClientDetailPatronymic' /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control value={this.state.clientInfo.Patronymic} onChange={(e: any) => this.onPatronymicChanged(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group></>)}
                            <Form.Group controlId="customerDetail.Phones">
                                {this.state.clientInfo.Phones.map((p,i) => (
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><Translate id='ClientDetailPhone' /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control value={p.PhoneNumber} isInvalid={!this.IsNumberValid(p.PhoneNumber)} onChange={(e: any) => this.onPhoneChanged(e.target.value, i)} />
                                        <InputGroup.Append>
                                            <Button variant="danger" onClick={(e: any) => this.onDeletePhone(e.target.value, i)}>-</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                ))}
                                <Button variant="info" onClick={this.onAddNewPhone.bind(this)}><Translate id='ClientDetailAddPhone'/></Button>
                            </Form.Group>

                            {!this.state.clientInfo.IsLegalEntity ? (
                            <Form.Group controlId="customerDetail.Email">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Email</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control value={this.state.clientInfo.Emails[0].Email} isInvalid={!this.IsEmailValid(this.state.clientInfo.Emails[0].Email)} onChange={(e: any) => this.onEmailChanged(e.target.value, 0)} />
                                </InputGroup>
                                </Form.Group>) :
                                (
                            <Form.Group controlId="customerDetail.Emails">
                                {this.state.clientInfo.Emails.map((email, i) => (
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Email</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control value={email.Email} isInvalid={!this.IsEmailValid(email.Email)} onChange={(e: any) => this.onEmailChanged(e.target.value, i)} />
                                        <InputGroup.Append>
                                            <Button variant="danger" onClick={(e: any) => this.onDeleteEmail(e.target.value, i)}>-</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                ))}
                                    <Button variant="info" onClick={this.onAddNewEmail.bind(this)}><Translate id='ClientDetailAddEmail' /></Button>
                                </Form.Group>
                                    )}
                            <Form.Group controlId="customerDetail.INN">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><Translate id='ClientDetailINN'/></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control value={this.state.clientInfo.INN} onChange={(e: any) => this.onINNChanged(e.target.value)}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="customerDetail.Saving">
                                <ButtonGroup>
                                    
                                    <Button variant="success" onClick={(e: any) => this.onSaving(e)}><Translate id={this.state.clientInfo.Id > 0 ? 'ClientDetailSave' : 'ClientDetailAddCompany'}/></Button>
                                    <Button variant="danger" onClick={(e: any) => this.close()}><Translate id='ClientDetailCancel'/></Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    translate: getTranslate(state.localize),
    clients: state.customers.foundCustomers,
    licenceKey: state.user.licenceKey,
});
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
    saveClient: (key: string, client: ClientInfo) => dispatch(actions.customers.saveCustomersActionCreator(key, client)),
});

export const ConnectedClientDetail = connect(
    mapStateToProps, mapDispatchToProps, null, { forwardRef : true}
)(ClientDetail);