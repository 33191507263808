
class EventDispatcher {

  private _subscriptions: Array<() => void> = new Array<() => void>();

  public subscribe(fn: () => void): void {
    if (fn) {
      this._subscriptions.push(fn);
    }
  }

  public unsubscribe(fn: () => void): void {
    if (fn == null) {
      this._subscriptions = new Array<() => void>();
      return;
    }
    let i = this._subscriptions.indexOf(fn);
    if (i > -1) {
      this._subscriptions.splice(i, 1);
    }
  }
  public unsubscribeAll() {
    this._subscriptions = new Array<() => void>();
  }

  public dispatch(): void {
    for (let handler of this._subscriptions) {
      handler();
    }
  }
}

export default EventDispatcher;