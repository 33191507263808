import { UserInfo, ILoginInfo, UserState } from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ServerProxy } from "../../RemoteCommands/ServerProxy";
import { WebServiceLoginResults } from "../../RemoteCommands/ResultTypes"


export enum UserActionTypes {
    AUTHORIZING = 'AUTHORIZING',
    AUTHORIZING_WITH_KEY = 'AUTHORIZING_WITH_KEY',
    AUTHORIZING_WITH_ACTIVATION_KEY = 'AUTHORIZING_WITH_ACTIVATION_KEY',
    AUTHORIZED = 'AUTHORIZED',
    AUTHORIZE_FAILURE = 'AUTHORIZE_FAILURE',

    LOGOUT = 'LOGOUT',

    SENDING_ACTIVATION_KEY = 'SENDING_ACTIVATION_KEY',
    SENT_ACTIVATION_KEY = 'SENT_ACTIVATION_KEY',
    SENDING_ACTIVATION_KEY_FAILURE = 'SENDING_ACTIVATION_KEY_FAILURE',
}
export interface IAuthorizingAction extends Action<UserActionTypes.AUTHORIZING> {
    loginInfo: ILoginInfo,
}
export interface IAuthorizingWithKeyAction extends Action<UserActionTypes.AUTHORIZING_WITH_KEY> {
    key: string,
}
export interface IAuthorizingWithActivationKeyAction extends Action<UserActionTypes.AUTHORIZING_WITH_ACTIVATION_KEY> {
    key: string,
}
export interface IAuthorizedAction extends Action<UserActionTypes.AUTHORIZED> {
    userInfo: UserInfo,
    licenceKey: string,
    sipLogin?: string,
    sipPassword?: string,
}
export interface IAuthorizeFailureAction extends Action<UserActionTypes.AUTHORIZE_FAILURE> {
    error: string,
    clearKey: boolean,
}
export interface ILogoutAction extends Action<UserActionTypes.LOGOUT> {}
export interface ISendingActivationKeyAction extends Action<UserActionTypes.SENDING_ACTIVATION_KEY> {
    phone: string,
}
export interface ISentActivationKeyAction extends Action<UserActionTypes.SENT_ACTIVATION_KEY> {
}
export interface ISendingActivationKeyFailureAction extends Action<UserActionTypes.SENDING_ACTIVATION_KEY_FAILURE> {
    error: string,
}

export type UserActions =
    | IAuthorizingAction
    | IAuthorizingWithKeyAction
    | IAuthorizingWithActivationKeyAction
    | IAuthorizedAction
    | IAuthorizeFailureAction
    | ILogoutAction
    | ISendingActivationKeyAction
    | ISentActivationKeyAction
    | ISendingActivationKeyFailureAction;

export const authorizeActionCreator: ActionCreator<ThunkAction<Promise<any>, UserState, ILoginInfo, IAuthorizedAction>> = (loginInfo: ILoginInfo) => {
    return async (dispatch: ThunkDispatch<UserState, void, Action<any>>) => {
        try {
            const authAction: IAuthorizingAction = {
                type: UserActionTypes.AUTHORIZING,
                loginInfo: loginInfo
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(authAction);
            var result = await ServerProxy.Login(loginInfo.username, loginInfo.password);
            if (result.Username && result.Id && result.LicenceKey) {
                const successAction: IAuthorizedAction = {
                    type: UserActionTypes.AUTHORIZED,
                    userInfo: { username: result.Username, id: result.Id },
                    licenceKey: result.LicenceKey,
                    sipLogin: result.SipLogin,
                    sipPassword: result.SipPassword,
                };
                dispatch(successAction);
            } else {
                const failAction: IAuthorizeFailureAction = {
                    type: UserActionTypes.AUTHORIZE_FAILURE,
                    error: result.ErrorMessage === undefined ? "������ �����������" : result.ErrorMessage,
                    clearKey: false
                };
                (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
            }
        } catch (e) {
            console.log(e);
            const failAction: IAuthorizeFailureAction = {
                type: UserActionTypes.AUTHORIZE_FAILURE,
                error: e.message,
                clearKey: false
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
        }
    }
}

export const authorizeWithKeyActionCreator: ActionCreator<ThunkAction<Promise<any>, UserState, string, IAuthorizedAction>> = (key: string) => {
    return async (dispatch: ThunkDispatch<UserState, void, Action<any>>) => {
        try {
            const authAction: IAuthorizingWithKeyAction = {
                type: UserActionTypes.AUTHORIZING_WITH_KEY,
                key: key
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(authAction);
            var result = await ServerProxy.LoginWithLicenceKey(key);
            if (result.Username && result.Id && result.LicenceKey) {
                const successAction: IAuthorizedAction = {
                    type: UserActionTypes.AUTHORIZED,
                    userInfo: { username: result.Username, id: result.Id },
                    licenceKey: result.LicenceKey,
                    sipLogin: result.SipLogin,
                    sipPassword: result.SipPassword,
                };
                dispatch(successAction);
            } else {
                const failAction: IAuthorizeFailureAction = {
                    type: UserActionTypes.AUTHORIZE_FAILURE,
                    error: result.ErrorMessage === undefined ? "������ �����������" : result.ErrorMessage,
                    clearKey: result.Error === WebServiceLoginResults.KeyIncorrect
                };
                (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
            }
        } catch (e) {
            console.log(e);
            const failAction: IAuthorizeFailureAction = {
                type: UserActionTypes.AUTHORIZE_FAILURE,
                error: e.message,
                clearKey: false
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
        }
    }
}

export const sendingActivationKeyActionCreator: ActionCreator<ThunkAction<Promise<any>, UserState, string, ISentActivationKeyAction>> = (phone: string) => {
    return async (dispatch: ThunkDispatch<UserState, void, Action<any>>) => {
        try {
            const sendKeyAction: ISendingActivationKeyAction = {
                type: UserActionTypes.SENDING_ACTIVATION_KEY,
                phone: phone
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(sendKeyAction);
            ServerProxy.SendActivationKey(phone).then(() => {
                const successAction: ISentActivationKeyAction = {
                    type: UserActionTypes.SENT_ACTIVATION_KEY
                };
                dispatch(successAction);
            }).catch((e) => {
                const failAction: ISendingActivationKeyFailureAction = {
                    type: UserActionTypes.SENDING_ACTIVATION_KEY_FAILURE,
                    error: e.message
                };
                (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
            });
        } catch (e) {
            console.log(e);
            const failAction: ISendingActivationKeyFailureAction = {
                type: UserActionTypes.SENDING_ACTIVATION_KEY_FAILURE,
                error: e.message
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
        }
    }
}

export const authorizeWithActivationKeyActionCreator: ActionCreator<ThunkAction<Promise<any>, UserState, string, IAuthorizedAction>> = (key: string) => {
    return async (dispatch: ThunkDispatch<UserState, void, Action<any>>) => {
        try {
            const authAction: IAuthorizingWithActivationKeyAction = {
                type: UserActionTypes.AUTHORIZING_WITH_ACTIVATION_KEY,
                key: key
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(authAction);
            var result = await ServerProxy.LoginWithActivationKey(key);
            if (result.Username && result.Id && result.LicenceKey) {
                const successAction: IAuthorizedAction = {
                    type: UserActionTypes.AUTHORIZED,
                    userInfo: { username: result.Username, id: result.Id },
                    licenceKey: result.LicenceKey,
                    sipLogin: result.SipLogin,
                    sipPassword: result.SipPassword,
                };
                dispatch(successAction);
            } else {
                const failAction: IAuthorizeFailureAction = {
                    type: UserActionTypes.AUTHORIZE_FAILURE,
                    error: result.ErrorMessage === undefined ? "������ �����������" : result.ErrorMessage,
                    clearKey: result.Error === WebServiceLoginResults.KeyIncorrect
                };
                (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
            }
        } catch (e) {
            console.log(e);
            const failAction: IAuthorizeFailureAction = {
                type: UserActionTypes.AUTHORIZE_FAILURE,
                error: e.message,
                clearKey: false
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(failAction);
        }
    }
}

export const logoutActionCreator: ActionCreator<ThunkAction<Promise<any>, UserState, string, IAuthorizedAction>> = () => {
    return async (dispatch: ThunkDispatch<void, void, Action<any>>) => {
        try {
            const logoutAction: ILogoutAction = {
                type: UserActionTypes.LOGOUT
            };
            (dispatch as ThunkDispatch<UserState, void, Action<any>>)(logoutAction);
            ServerProxy.Logout().catch(e => console.log(e));
        } catch (e) {
            console.log(e);
        }
    }
}