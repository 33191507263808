import React from "react";
import { connect } from 'react-redux';
import { RootState, selectors } from '../store';
import { withRouter, RouteComponentProps } from 'react-router';
import { Billboard } from "../store/billboards/types";
import BillboardListItem from "./BillboardListItem"
import BillBoardDetail from "../pages/BillBoardDetail"
import { withLocalize, LocalizeContextProps, Translate, getTranslate, TranslateFunction } from "react-localize-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

type StateProps = {
    billboards: Billboard[],
    translateFunc: TranslateFunction
}

type Props = RouteComponentProps<{}> & StateProps & LocalizeContextProps & {
    billboards: Billboard[],
    translateFunc: TranslateFunction,
}

class BillboardList extends React.Component<Props> {

    modalForList: React.RefObject<BillBoardDetail>;

    constructor(props: Props) {
        super(props);
        this.modalForList = React.createRef();
    }
    navigateToBillboard = (billboardId: number) => () => {
        if (this.modalForList.current) {
            this.modalForList.current.setBillboard(billboardId);
            this.modalForList.current.open();
        }
    }
    render() {
        if (this.props.billboards.length === 0)
        return (
            <div>
                <Translate id='BillboardListNoBoards'/>
            </div>
        );


        return (
            <div>
                {/* <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th><Translate id='BillboardListAddress'/></th>
                            <th><Translate id='BillboardListFormat' /></th>
                            <th><Translate id='BillboardListDirection' /></th>
                            <th><Translate id='BillboardListGid' /></th>
                            <th><Translate id='BillboardListType' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.billboards.map((billboard: Billboard, billboardIndex: number) => (
                            <BillboardListItem key={billboard.id} onClick={this.navigateToBillboard(billboard.id)
} billboard={billboard}/>
                        ))}
                    </tbody>
                </Table> */}
                {/* <BillBoardDetail ref={this.modalForList} billboards={this.props.billboards} translate={this.props.translateFunc}/> */}
                <Paper>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell><Translate id='BillboardListAddress'/></TableCell>
                            <TableCell align="center"><Translate id='BillboardListFormat' /></TableCell>
                            <TableCell align="center"><Translate id='BillboardListDirection' /></TableCell>
                            <TableCell align="center"><Translate id='BillboardListGid' /></TableCell>
                            <TableCell align="right"><Translate id='BillboardListType' /></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.billboards.map((billboard: Billboard, billboardIndex: number) => (
                            <BillboardListItem key={billboard.id} onClick={this.navigateToBillboard(billboard.id)} billboard={billboard}/>
                        ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    billboards: selectors.billboards.allBillboards(state.billboards),
    translateFunc: getTranslate(state.localize),
});


export default withLocalize(withRouter(connect(
    mapStateToProps
)(BillboardList)));
