import React from 'react';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { Dropdown} from 'react-bootstrap';

const LanguageSelector = (props: LocalizeContextProps) => (
    <div>
        <Dropdown>
            <Dropdown.Toggle id="lang_nav_menu" variant="outline-light"><i className="fas fa-globe"></i>{props.activeLanguage.name}</Dropdown.Toggle>
            <Dropdown.Menu className="btn btn-outline-light">
            <Dropdown.Item onClick={() => {
                props.setActiveLanguage("ru");
                localStorage.setItem("lang", "ru");
                }}><span className="flag-icon flag-icon-ru flag-icon-squared"/>Ру</Dropdown.Item>
            <Dropdown.Item onClick={() => {
                props.setActiveLanguage("en");
                localStorage.setItem("lang", "en");
                }}><span className="flag-icon flag-icon-us flag-icon-squared" />En</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
);

export default withLocalize(LanguageSelector);
