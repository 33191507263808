import { UserActions, UserActionTypes } from "./actions";
import { UserState } from "./types";
import { Reducer } from "redux";

const defaultState: UserState = {
    isAuthorized: false,
    isAuthorizing: false,
    inputingActivationKey: false,
    sendingActivationKey: false,
    licenceKey: localStorage.getItem('LicenceKey')
};

export const userReducer: Reducer<UserState, UserActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
    case UserActionTypes.AUTHORIZING:
    {
        return {
            ...state,
            isAuthorizing: true
        };
    }
    case UserActionTypes.AUTHORIZING_WITH_KEY:
    {
        return {
            ...state,
            isAuthorizing: true
        };
    }
    case UserActionTypes.AUTHORIZING_WITH_ACTIVATION_KEY:
    {
        return {
            ...state,
            isAuthorizing: true
        };
    }
    case UserActionTypes.AUTHORIZED:
    {
        localStorage.setItem('LicenceKey', action.licenceKey);
        return {
            ...state,
            isAuthorizing: false,
            isAuthorized: true,
            sendingActivationKey: false,
            inputingActivationKey: false,
            userInfo: action.userInfo,
            licenceKey: action.licenceKey,
            sipLogin: action.sipLogin,
            sipPassword: action.sipPassword,
        };
    }
    case UserActionTypes.AUTHORIZE_FAILURE:
    {
        if (action.clearKey)
            localStorage.removeItem("LicenceKey");
        return {
            ...state,
            isAuthorizing: false,
            sendingActivationKey: false,
            inputingActivationKey: false,
            lastError: action.error,
            licenceKey: action.clearKey ? null : state.licenceKey
        };
    }
    case UserActionTypes.LOGOUT:
    {
        localStorage.removeItem("LicenceKey");
        return {
            ...state,
            isAuthorizing: false,
            isAuthorized: false,
            lastError: '',
            userInfo: undefined,
            licenceKey: null,
            sipLogin: undefined,
            sipPassword: undefined
        };
    }
    case UserActionTypes.SENDING_ACTIVATION_KEY:
    {
        return {
            ...state,
            isAuthorizing: false,
            sendingActivationKey: true,
            lastError: '',
        };
    }
    case UserActionTypes.SENT_ACTIVATION_KEY:
    {
        return {
            ...state,
            sendingActivationKey: false,
            inputingActivationKey: true,
        };
    }
    case UserActionTypes.SENDING_ACTIVATION_KEY_FAILURE:
    {
        return {
            ...state,
            sendingActivationKey: false,
            inputingActivationKey: false,
            lastError: action.error,
        };
    }
    default:
        return state;
    }
};
export default userReducer;