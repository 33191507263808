import * as React from 'react';
import styled from 'styled-components';


interface MarkerProps {
    readonly status: string|undefined;
    readonly onClick: any;
    readonly text: string;
    readonly lat: number;
    readonly lng: number;
};

const Wrapper = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  line-height: 20px;
  background-color: #222;
  color:#fff;
  border-radius: 100%;
  border: 2px solid ${(props: MarkerProps) => props.status === 'свободна' ? '#00aa00' : '#dd0000'};
  user-select: none;
  text-align:center;
  font-size:0;
  transform: translate(-50%, -50%);
cursor: ${ (props: MarkerProps) => (props.onClick ? 'pointer' : 'default')};
  transition: width 0.1s, height 0.1s, font-size 0.1s;
  &:hover {
    z-index: 1;
    width: 60px;
    height: 25px;
    font-size:xx-small;
    border-radius: 20%/40%;
  }
`;
const MapMarker: React.SFC<MarkerProps> = (props) => {
    return (<Wrapper onClick={props.onClick} status={props.status} text={props.text} lat={props.lat} lng={props.lng}>{props.text}</Wrapper>);
}

export default MapMarker;
