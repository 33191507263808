import {RemoteCommandJson} from './RemoteCommandJson';
import { RemoteCommandCallback } from './ServerProxy';
import {List} from './List';

export class RemoteCommandCallbackData<ReturnType> {
  public IsCommandDelivered: boolean;
  public Exception: string | null;
  public Result: ReturnType;

  constructor(isCommandDelivered: boolean, exception: string | null, result: any) {
    this.IsCommandDelivered = isCommandDelivered;
    this.Exception = exception;
    this.Result = result;
  }
}

export class RemoteCommandSynchronizer<ReturnType> {

  public CommandId: string;
  public Callback: RemoteCommandCallback<ReturnType>;

  public CreateTime: Date;

  constructor(commandId: string, callback: RemoteCommandCallback<ReturnType>) {
    this.CommandId = commandId;
    this.Callback = callback;
    this.CreateTime = new Date();
  }

  public StopWait(command: RemoteCommandJson) {
      this.Callback(new RemoteCommandCallbackData<any>(true, command.Exception!, command.Result ? JSON.parse(command.Result) : null));
  }

  public StopWaitCallback() {
    this.Callback(new RemoteCommandCallbackData<any>(false, "timeout 30 sec", null));
  }
}

export class RemoteCommandSynchronizerCollection {

  private static commands: List<RemoteCommandSynchronizer<any>> = new List([]);
  private static timer: any;

  public static Initialize() {
    RemoteCommandSynchronizerCollection.timer = setInterval(RemoteCommandSynchronizerCollection.Tick, 1000);
  }

    private static Tick() {

    RemoteCommandSynchronizerCollection.commands.GetItems().forEach((value) => {
      var diffMs = new Date().getTime() - value.CreateTime.getTime();
      if (diffMs > 30000) {//30 sec
        value.StopWaitCallback();
        RemoteCommandSynchronizerCollection.commands.Remove(value);
      }
    });
  }

  public static Add(commandSynchronizer: RemoteCommandSynchronizer<any>) {

    var old = RemoteCommandSynchronizerCollection.commands.FirstOrDefault(item => {
      return item.CommandId === commandSynchronizer.CommandId;
    });
    if (old != null) {
      old.StopWaitCallback();
      RemoteCommandSynchronizerCollection.commands.Remove(old);
    }

    RemoteCommandSynchronizerCollection.commands.Add(commandSynchronizer);
  }

  public static Stop(commandId: string, remoteCommand: RemoteCommandJson): RemoteCommandSynchronizer<any> | null {
    var synchronizer = RemoteCommandSynchronizerCollection.commands.FirstOrDefault(item => {
      return item.CommandId === commandId;
    });
    if (synchronizer != null) {
      synchronizer.StopWait(remoteCommand);
      RemoteCommandSynchronizerCollection.commands.Remove(synchronizer);
    }
    return synchronizer;
  }
}
export default RemoteCommandSynchronizerCollection;