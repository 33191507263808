import { SipActions, SipActionTypes } from "./actions";
import { SipState } from './types';
import { Reducer } from "redux";

const defaultState: SipState = {
    isInitialized: false,
    isInitializing: false,
    lastError: '',
    username: '',
    phoneNumber: '',
    hasActiveCall: false,
    isEstablished: false,
    isIncoming: false,
    isMuted: false,
    callsHistory: [],
};

export const sipReducer: Reducer<SipState, SipActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case SipActionTypes.INITIALIZE_SIP:
            {
                return {
                    ...state,
                    isInitialized: false,
                    isInitializing: true
                };
            }
        case SipActionTypes.INITIALIZE_SIP_SUCCESS:
        {
            return {
                ...state,
                isInitialized: true,
                isInitializing: false,
                username: action.username,
                callsHistory: action.calls
        };
            }
        case SipActionTypes.INITIALIZE_SIP_FAIL:
            {
                return {
                    ...state,
                    isInitialized: false,
                    isInitializing: false,
                    lastError: action.error
                };
            }
        case SipActionTypes.SIP_STATE_CHANGED:
            {
                return {
                    ...state,
                    phoneNumber: action.phoneNumber,
                    hasActiveCall: action.hasActiveCall,
                    isEstablished: action.isEstablished,
                    isIncoming: action.isIncoming,
                    isMuted: action.isMuted,
                };
            }
        case SipActionTypes.SIP_INCOMING_CALL:
            {
                return {
                    ...state,
                    phoneNumber: action.phoneNumber,
                    hasActiveCall: true,
                    isEstablished: false,
                    isIncoming: true,
                };
            }
        case SipActionTypes.SIP_ADD_CALL_HISTORY:
        {
            let callsHistory = ([
                ...state.callsHistory,
                action.call
            ]).sort((a, b) => b.time < a.time ? -1 : 1).slice(0,20);
            localStorage.setItem('calls_' + state.username, JSON.stringify(callsHistory));
            return {
                ...state,
                callsHistory: callsHistory
            };
            }
        default:
            return state;
    }
};
export default sipReducer;