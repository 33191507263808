import { BillboardState } from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { WebServerProxy } from "../../WebServerApi/WebServerProxy";
import { GeoMark, PropertyValueSet, Translation, ClientInfo, IPropertySet } from "../../WebServerApi/webServerTypes";
import { RootState } from '..';

export enum BillboardActionTypes {
    FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS',
    FETCH_TRANSLATIONS_ERROR = 'FETCH_TRANSLATIONS_ERROR',
    FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS',
    INITIALIZE = 'INITIALIZE_ACTION',
    INITIALIZE_ERROR = 'INITIALIZE_ERROR',
    INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS',
}


export interface IFetchingTranslationsAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS> {
}
export interface IFetchTranslationsErrorAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS_ERROR> {
    error: string,
}
export interface IFetchTranslationsSuccessAction extends Action<BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS> {
    translations: Translation[],
}
export interface IInitializeAction extends Action<BillboardActionTypes.INITIALIZE> {
}
export interface IInitializeErrorAction extends Action<BillboardActionTypes.INITIALIZE_ERROR> {
    error: string,
}
export interface IInitializeSuccessAction extends Action<BillboardActionTypes.INITIALIZE_SUCCESS> {
    propertySets: PropertyValueSet[],
    geoMarks: GeoMark[],
    companies: ClientInfo[],
}

export type BillboardActions =
    | IInitializeAction
    | IInitializeErrorAction
    | IInitializeSuccessAction
    | IFetchingTranslationsAction
    | IFetchTranslationsErrorAction
    | IFetchTranslationsSuccessAction;

export const initializeActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IInitializeSuccessAction>> = (key: string) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const fetchingAction: IInitializeAction = {
                type: BillboardActionTypes.INITIALIZE
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(fetchingAction);
            WebServerProxy.getAllCompanies(key).then(companies => {
                WebServerProxy.getBillboards(key).then(geomarks => {
                    var propertySetIds = [
                        ...new Set(geomarks.map((value) => { return value.PropertyValueSetId }).filter(id => id > 0))
                    ];
                    if (propertySetIds.length > 0) {
                        WebServerProxy.getProperties(key, propertySetIds).then(properties => {
                            const successAction: IInitializeSuccessAction = {
                                type: BillboardActionTypes.INITIALIZE_SUCCESS,
                                geoMarks: geomarks,
                                propertySets: properties,
                                companies: companies
                            };
                            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(successAction);
                        });
                    }
                });
            }).catch(e => {
                const failAction: IInitializeErrorAction = {
                    type: BillboardActionTypes.INITIALIZE_ERROR,
                    error: e.message
                };
                (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
            });
        } catch (e) {
            const failAction: IInitializeErrorAction = {
                type: BillboardActionTypes.INITIALIZE_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        }
    }
}

export const fetchTranslationsActionCreator: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IFetchTranslationsSuccessAction>> = (key: string) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const fetchingAction: IFetchingTranslationsAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(fetchingAction);
            WebServerProxy.getTranslations().then(result => {
                const successAction: IFetchTranslationsSuccessAction = {
                    type: BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS,
                    translations: result
                };
                (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(successAction);
            }).catch(e => {
                const failAction: IFetchTranslationsErrorAction = {
                    type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                    error: e.message
                };
                (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
            });
        } catch (e) {
            const failAction: IFetchTranslationsErrorAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        }
    }
}


export const updateBillboard: ActionCreator<ThunkAction<Promise<any>, BillboardState, string, IFetchTranslationsSuccessAction>> = (key: string,propertySet:IPropertySet, geomark: GeoMark) => {
    return async (dispatch: ThunkDispatch<BillboardState, void, Action<any>>) => {
        try {
            const fetchingAction: IFetchingTranslationsAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(fetchingAction);
                var propertyValueSetId = await WebServerProxy.savePropertySet(key,propertySet);
                geomark.PropertyValueSetId = propertyValueSetId;
                var geomarkId = await WebServerProxy.SetGeoMark(key, geomark);
                console.log(geomarkId);
                const failAction: IFetchTranslationsErrorAction = {
                    type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                    error: "Test error"
                };
                (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        } catch (e) {
            const failAction: IFetchTranslationsErrorAction = {
                type: BillboardActionTypes.FETCH_TRANSLATIONS_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<BillboardState, void, Action<any>>)(failAction);
        }
    }
}