import { AppActions, AppActionTypes } from "./actions";
import { AppState } from './types';
import { Reducer } from "redux";

const defaultState: AppState = {
    showPhoneControl:false,
    showFullMenu: true,
};

export const appReducer: Reducer<AppState, AppActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case AppActionTypes.CHANGE_PHONE_CONTROL_STATE:
            {
                return {
                    ...state,
                    showPhoneControl: !state.showPhoneControl,
                };
            }
        case AppActionTypes.CHANGE_MENU_STATE:
            {
                return {
                    ...state,
                    showFullMenu: !state.showFullMenu,
                };
            }
        default:
            return state;
    }
};
export default appReducer;