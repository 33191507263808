import { Action} from 'redux';

export enum AppActionTypes {
    CHANGE_PHONE_CONTROL_STATE = 'CHANGE_PHONE_CONTROL_STATE',
    CHANGE_MENU_STATE = 'CHANGE_MENU_STATE',
}


export interface IChangePhoneControlStateAction extends Action<AppActionTypes.CHANGE_PHONE_CONTROL_STATE> {}
export interface IChangeMenuStateAction extends Action<AppActionTypes.CHANGE_MENU_STATE> {}

export type AppActions =
    | IChangePhoneControlStateAction
    | IChangeMenuStateAction;

export function changePhoneControlStateAction() {
    const action: IChangePhoneControlStateAction = {
        type: AppActionTypes.CHANGE_PHONE_CONTROL_STATE
    }
    return action;
}
export function changeMenuStateAction() {
    const action: IChangeMenuStateAction = {
        type: AppActionTypes.CHANGE_MENU_STATE
    }
    return action;
}