import EventDispatcher from "../RemoteCommands/Events";
import { actions } from "../store";
import store from "../store";
import { CallInfo } from "../store/sip/types";
import * as request from "request-promise-native";
import { Consts } from "../store/Consts";
import { CallerInfo } from "./sipTypes";

export class Sip {
    private static phone: any;
    private static session: any;
    private static configuration: any;
    private static incomingCallAudio: any;
    private static callOptions: any;
    private static userNumber:string;
    private static callingNumber?:string;
    private static remoteAudio: any;
    private static isWork: boolean;

    static OnSipChanged = new EventDispatcher();

    static Initialize(uri: string, password: string, sipServer: string) {

        Sip.configuration = {
            uri: uri,
            password: password,
            ws_servers: sipServer
        };
        this.userNumber = uri.match(':(.*)@')!.pop()!;
        //todo заменить звук
        Sip.incomingCallAudio = new (window as any).Audio("https://www.doubango.org/sipml5/sounds/ringtone.wav");
        Sip.incomingCallAudio.loop = true;
        Sip.incomingCallAudio.preload = "auto";
        Sip.incomingCallAudio.pause();
        this.remoteAudio = new (window as any).Audio();
        this.remoteAudio.autoplay = true;

        Sip.callOptions = {
            mediaConstraints: { audio: true, video: false }
        };

        if (Sip.configuration.uri && Sip.configuration.password) {
            //(window as any).JsSIP.debug.enable("JsSIP:*"); // more detailed debug output
            Sip.phone = new (window as any).JsSIP.UA(Sip.configuration);

            Sip.phone.on("registrationFailed",
                (ev: any) => {
                    alert(`Registering on SIP server failed with error: ${ev.cause}`);
                    Sip.configuration.uri = null;
                    Sip.configuration.password = null;
                    Sip.updateUI();
                });

            Sip.phone.on("newRTCSession",
                (ev: any) => {
                    var newSession = ev.session;
                    if (newSession.direction === "incoming" && newSession.remote_identity.uri.user === Consts.SipAgentNumber) {
                        this.GetInfoFromPhoneAgent(this.userNumber).then((res) => {
                            let callerInfo = JSON.parse(res) as CallerInfo;
                            if (callerInfo) {
                                this.callingNumber = callerInfo.numberFrom;
                                this.handleCall(newSession);
                            }
                        });
                    } else {
                        this.handleCall(newSession);
                    }
                });
            Sip.phone.start();
        }
        Sip.updateUI();
        Sip.isWork = true;
        Sip.UpdateStatusOnPhoneAgentLoop(this.userNumber);
    }

    private static handleCall(newSession:any) {
        if (Sip.session) { // hangup any existing call
            Sip.session.terminate();
        }
        Sip.session = newSession;
        var completeSession = () => {
            Sip.incomingCallAudio.pause();
            Sip.session = null;
            Sip.callingNumber = undefined;
            Sip.isWork = true;
            Sip.UpdateStatusOnPhoneAgent(this.userNumber, true);
            Sip.updateUI();
        };
        Sip.session.on("ended", completeSession);
        Sip.session.on("failed", completeSession);
        Sip.session.on("accepted", Sip.updateUI);
        Sip.session.on("confirmed",
            () => {
                var localStream = Sip.session.connection.getLocalStreams()[0];
                var dtmfSender = Sip.session.connection.createDTMFSender(localStream.getAudioTracks()[0]);
                Sip.session.sendDTMF = (tone: any) => {
                    dtmfSender.insertDTMF(tone);
                };
                Sip.isWork = false;
                Sip.UpdateStatusOnPhoneAgent(this.userNumber, false);
                Sip.updateUI();
            });
        Sip.session.on("addstream",
            (e: any) => {
                Sip.incomingCallAudio.pause();
                var stream = e.stream;
                this.remoteAudio.srcObject = stream;
            });
        if (Sip.session.direction === "incoming") {
            store.dispatch<any>(actions.sip.sipIncomingCallAction(Sip.PhoneNumber));
            Sip.incomingCallAudio.play();
        }
        Sip.updateUI();
    }

    static get IsInitialized(): boolean {
        return Sip.configuration && Sip.configuration.uri && Sip.configuration.password;
    }

    static get PhoneNumber(): string {
        try {
            return Sip.callingNumber ? Sip.callingNumber : Sip.session.remote_identity.uri.user;
        } catch (e) {
            return "";
        }
    }

    static get HasActiveCall(): boolean {
        return Sip.session;
    }

    static get IsEstablished(): boolean {
        return Sip.session && Sip.session.isEstablished();
    }

    static get IsIncomingCall(): boolean {
        return Sip.session && Sip.session.direction === "incoming";
    }

    static get IsMuted(): boolean {
        return Sip.session && Sip.session.isMuted().audio;
    }

    static set IsMuted(isMuted: boolean) {
        if (Sip.HasActiveCall === false)
            return;

        if (isMuted) {
            Sip.session.mute({ audio: true });
        } else {
            Sip.session.unmute({ audio: true });
        }
        Sip.updateUI();
    }

    static Call(from:string, phone: string) {

        this.InformPhoneAgentAboutCall(from, phone).then(() => {
            Sip.phone.call(phone, Sip.callOptions);
            store.dispatch(actions.sip.sipAddCallHistoryAction(new CallInfo(new Date(), phone, false, false)));
            Sip.updateUI();
        }).catch((e) => {

        });

    }

    static Answer() {
        if (Sip.HasActiveCall === false)
            return;

        Sip.session.answer(Sip.callOptions);
        Sip.updateUI();
    }

    static HangUp() {
        if (Sip.HasActiveCall === false)
            return;
        Sip.session.terminate();
        Sip.StopIncomingCallAudio();
        Sip.updateUI();
    }

    static SendDTMF(char: string) {
        if (Sip.HasActiveCall === false)
            return;
        Sip.session.sendDTMF(char);
    }

    static StopIncomingCallAudio() {
        Sip.incomingCallAudio.pause();
    }


    private static updateUI() {
        Sip.OnSipChanged.dispatch();
        store.dispatch(actions.sip.sipStateChangedAction(this.PhoneNumber, this.HasActiveCall, this.IsEstablished, this.IsIncomingCall, this.IsMuted));
    }

    //http://localhost:9012/api/WebBrowserSip/CallToPhone?dispatcherPhone=101&destinationPhone=301
    private static async InformPhoneAgentAboutCall(from: string, to: string): Promise<any> {
        if (to.length < 5)
            return Promise.resolve();
        const baseUrl = window.location.hostname === 'localhost'
            ? `http://${window.location.hostname}:9012/api/WebBrowserSip/CallToPhone`
            : `https://${window.location.hostname}:9013/api/WebBrowserSip/CallToPhone`;
        const queryString = `?dispatcherPhone=${from}&destinationPhone=${to}`;
        const options = {
            uri: baseUrl + queryString,
            
        };

        return await request.get(options);
    }
    //http://localhost:9012/api/WebBrowserSip/GetCallerPhone?dispatcherPhone=101
    private static async GetInfoFromPhoneAgent(dispPhone: string): Promise<string> {
        const baseUrl = window.location.hostname === 'localhost'
            ? `http://${window.location.hostname}:9012/api/WebBrowserSip/GetCallerPhone`
            : `https://${window.location.hostname}:9013/api/WebBrowserSip/GetCallerPhone`;
        const queryString = `?dispatcherPhone=${dispPhone}`;
        const options = {
            uri: baseUrl + queryString,
            
        };

        return await request.get(options);
    }
    //http://localhost:9012/api/WebBrowserSip/SetSipStatus?dispatcherPhone=101
    private static async UpdateStatusOnPhoneAgent(dispPhone: string, isWork: boolean): Promise<void> {
        try {
            const baseUrl = window.location.hostname === 'localhost'
                ? `https://blackboard.sedi.ru:9013/api/WebBrowserSip/SetSipStatus`
                : `https://${window.location.hostname}:9013/api/WebBrowserSip/SetSipStatus`;
            const queryString = `?dispatcherPhone=${dispPhone}&isWork=${isWork}`;
            const options = {
                uri: baseUrl + queryString,

            };

            return await request.post(options);
        } catch (e) {
            console.log(e);
        }
        Promise.resolve();
    }

    private static async UpdateStatusOnPhoneAgentLoop(dispPhone: string) {
        Sip.UpdateStatusOnPhoneAgent(dispPhone, Sip.isWork).then(() => {
            setTimeout(() => Sip.UpdateStatusOnPhoneAgentLoop(dispPhone), 1000 * 90);
        });
    }
}