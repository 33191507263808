import React from "react";
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from 'react-router';
import { ILoginInfo, UserInfo } from "../store/user/types";
import { Form } from "react-bootstrap"
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { withLocalize, LocalizeContextProps, Translate, getTranslate, TranslateFunction } from "react-localize-redux";


type DispatchProps = {
    login: (loginInfo: ILoginInfo) => Promise<void>;
    sendKey: (phone: string) => Promise<void>;
    loginWithKey: (key: string) => Promise<void>;
}

type StateProps = {
    userInfo?: UserInfo;
    loginError?: string;
    inputingActivationKey: boolean;
    isAuthorizing: boolean;
    sendingActivationKey: boolean;
    translateFunc: TranslateFunction;
}

type Props = RouteComponentProps<{}> & DispatchProps & StateProps & LocalizeContextProps
type State = {
    errorMessage:string,
    login:string,
    password: string,
    activationKey: string,
    phone: string,
}
class LoginPage extends React.Component<Props, State> {
    state = {
        errorMessage: '',
        login: '',
        password: '',
        activationKey: '',
        phone: ''
    }

    setLogin(e: React.FormEvent<HTMLInputElement>) {
        this.setState({login:e.currentTarget.value});
    }
    setPassword(e: React.FormEvent<HTMLInputElement>) {
        this.setState({password:e.currentTarget.value});
    }
    setPhone(e: React.FormEvent<HTMLInputElement>) {
        this.setState({phone:e.currentTarget.value});
    }
    setActivationKey(e: React.FormEvent<HTMLInputElement>) {
        var activationKey = e.currentTarget.value;
        this.setState({ activationKey: activationKey });
        if (activationKey.length === 4) {
            this.props.loginWithKey(activationKey);
        }
    }

    handleSubmit(e: any) {
        e.preventDefault();
        this.props.login({username:this.state.login, password:this.state.password});
    }
    handleSendKey(e: any) {
        this.props.sendKey(this.state.phone);
    }
    render() {
        return (
            <div style={{ margin: 'auto', padding:'70px 0'}}>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-group">
                        <input type="text" id="loginInput" className="form-control" name="loginInput" placeholder={this.props.translateFunc('LoginLogin') as string} onChange={this.setLogin.bind(this)}/>
                    </div>
                    <div className="form-group">
                        <input type="password" id="passwordInput" className="form-control" name="passwordInput" placeholder={this.props.translateFunc('LoginPassword') as string} onChange={this.setPassword.bind(this)}/>
                    </div>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder={this.props.translateFunc('LoginPhone') as string} onChange={this.setPhone.bind(this)}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-primary" type="button" onClick={this.handleSendKey.bind(this)}><Translate id='LoginSendKey' /></button>
                        </div>
                    </div>
                    {this.props.inputingActivationKey ? 
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon3"><Translate id='LoginKey'/></span>
                            </div>
                            <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" onChange={this.setActivationKey.bind(this)}/>
                        </div>
                        : ""}
                    {this.props.loginError ?
                        <div className="alert alert-danger" role="alert">{this.props.loginError}</div>  : ""}
                    {this.props.isAuthorizing
                        ?
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Загрузка
                            </button>
                        :
                        <input type="submit" className="btn btn-primary" value={this.props.translateFunc('LoginAuthorize') as string}/>}
                </Form>
            </div>);
    };
}

const mapStateToProps = (state: RootState) => ({
    userInfo: state.user.userInfo,
    loginError: state.user.lastError,
    inputingActivationKey: state.user.inputingActivationKey,
    isAuthorizing: state.user.isAuthorizing,
    sendingActivationKey: state.user.sendingActivationKey,
    translateFunc: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
        login: (loginInfo: ILoginInfo) => dispatch(actions.user.authorizeActionCreator(loginInfo)),
        sendKey: (phone: string) => dispatch(actions.user.sendingActivationKeyActionCreator(phone)),
        loginWithKey: (key: string) => dispatch(actions.user.authorizeWithActivationKeyActionCreator(key)),
    });

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)));
