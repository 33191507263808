import { BillboardActions, BillboardActionTypes } from "./actions";
import { BillboardState, Billboard } from './types';
import { Reducer } from "redux";
import { GeoMark, PropertyValueSet, PropertiesHelper } from "../../WebServerApi/webServerTypes";

const defaultState: BillboardState = {
    billboards: [],
    properties: [],
    geomarks: [],
    translations: [],
    companies: [],
    isInitialized: false,
    isInitializing: false,
    translationsLoaded: false,
    translationsLoading: false,
};

export const billboardReducer: Reducer<BillboardState, BillboardActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case BillboardActionTypes.INITIALIZE_SUCCESS:
            {
                return {
                    ...state,
                    isInitializing: false,
                    geomarks: action.geoMarks,
                    properties: action.propertySets,
                    billboards: BillboardHelper.getBillBoards(action.geoMarks, action.propertySets),
                    companies: action.companies,
                    isInitialized: true
                };
            }
        case BillboardActionTypes.INITIALIZE_ERROR:
            {
                return {
                    ...state,
                    isInitializing: false,
                    isInitialized: false
                };
            }
        case BillboardActionTypes.INITIALIZE:
            {
                return {
                    ...state,
                    isInitializing: true,
                    isInitialized: false
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: true
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS_ERROR:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: false
                };
            }
        case BillboardActionTypes.FETCH_TRANSLATIONS_SUCCESS:
            {
                return {
                    ...state,
                    translationsLoaded: true,
                    translationsLoading: false,
                    translations: action.translations
                };
            }
        default:
            return state;
    }
};

class BillboardHelper {
    public static getBillBoards(geomarks: GeoMark[], properties: PropertyValueSet[]): Billboard[] {
        let result: Array<Billboard> = new Array<Billboard>();
        for (let i = 0; i < geomarks.length; i++) {
            let geomark = geomarks[i];
            if (!geomark || geomark.PropertyValueSetId <= 0)
                continue;
            var props = properties.find(pr => pr.PropertyValueSetId === geomark.PropertyValueSetId);
            if (!props || !PropertiesHelper.arePropertiesValid(props))
                continue;
            var billboard = Billboard.createBillBoard(geomark, props);
            if (billboard != null)
                result.push(billboard);
        }
        return result;
    }
}

export default billboardReducer;