export class List<T> {
  private items: Array<T>;

  constructor(items: Array<T> = []) {
    this.items = items;
  }

  Size(): number {
    return this.items.length;
  }

  Add(value: T): void {
    this.items.push(value);
  }

  Insert(value: T, index: number): void {
    this.items.splice(index, 0, value);
  }

  Remove(value: T) {
    this.items.splice(this.items.indexOf(value), 1);
  }

  Get(index: number): T {
    return this.items[index];
  }

  Sort(compareFn?: (a: T, b: T) => number): List<T> {
    var list = new List<T>();
    var sort = this.items.sort(compareFn);
    for (var i = 0; i < sort.length; i++) {
      list.Add(sort[i]);
    }
    return list;
  }

  Where(fn: (item: T) => boolean): List<T> {
    var list = new List<T>();
    for (var i = 0; i < this.Size(); i++) {
      var value = this.Get(i);
      if (fn(value))
        list.Add(value);
    }
    return list;
  }

  FirstOrDefault(fn: (item: T) => boolean): T | null {
    var result = this.Where(fn);
    if (result.Size() > 0)
      return result.Get(0);
    return null;
  }

  Foreach(fn: (item: T) => void): void {
    for (var i = 0; i < this.Size(); i++) {
      fn(this.Get(i));
    }
  }

  Any(fn: (item: T) => boolean): boolean {
    for (var i = 0; i < this.Size(); i++) {
      var value = this.Get(i);
      if (fn(value))
        return true;
    }
    return false;
  }

  GetItems(): Array<T> {
    return this.items;
  }

  Clear() {
    this.items = [];
  }
}
export default List;