import React from "react";
import { Modal, Container, Row, Col, ListGroup, Image } from "react-bootstrap"
import { Billboard } from "../store/billboards/types";
import {TranslateFunction } from "react-localize-redux";
import {Select,MenuItem,FormHelperText,FormControl,Input,InputLabel} from '@material-ui/core';
import { Grid, TextField, Button, ButtonGroup } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { WebServerProxy } from "../WebServerApi/WebServerProxy";
import { IPropertySet, IProperty } from "../WebServerApi/webServerTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
  }),
);


type Props = {
    billboards: Billboard[],
    translate: TranslateFunction,
    onUpdateBillboard: (propertySet:IPropertySet) => void
};

type State = {
    showModal:boolean;
    billboardId: number;
    selectedSide: string;
    boardEdit?: string | number;
    editToggle?: boolean;
    propertySet: IPropertySet,
    loading:boolean;
}

class BillboardDetail extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        // Эта привязка обязательна для работы `this` в колбэке.
        this.openEditForm = this.openEditForm.bind(this);
        this.closeEditForm = this.closeEditForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    state = {
        showModal: false,
        billboardId: 0,
        selectedSide: "A1",
        boardEdit: '',
        editToggle: false,
        propertySet: {
            PropertySetId: 0,
            PropertySetName: "",
            Properties: [],
        },
        loading:false,
    }

    openEditForm() {
        const billboard = this.props.billboards.find((b) => b.id === this.state.billboardId);
        if (!billboard)
            return;
        this.setState({loading:true})
        WebServerProxy.getPropertySet(billboard.propertyValueSetId)
            .then((propertySet) =>{
                this.setState({propertySet,loading:false,editToggle: true})
            })
            .catch(console.error);
    }

    handleSubmitForm(){
        this.props.onUpdateBillboard(this.state.propertySet);
        this.setState({editToggle: false});
    }

    closeEditForm() {
        this.setState({editToggle: false})
    }

    getInitialState() {
        return { showModal: false };
    }

    close() {
        this.setState({ showModal: false });
    }

    open() {
        this.setState({ showModal: true });
    }

    setBillboard(id:number) {
        this.setState({ billboardId:id });
    }

    setSide(side: string) {
        this.setState({ selectedSide: side });
    }

    getSidesLinks(billBoard: Billboard): JSX.Element {

        return (<div>
            {/* <ButtonGroup className="mr-2">{billBoard.getSideA().map(side => {
                return (<Button variant="outline-secondary" active={side === this.state.selectedSide} onClick={() => this.setSide(side)}>{side}</Button>);
            })}</ButtonGroup>
            <p/>
            <ButtonGroup className="mr-2">{billBoard.getSideB().map(side => {
                return (<Button variant="outline-secondary" active={side === this.state.selectedSide} onClick={() => this.setSide(side)}>{side}</Button>);
            })}</ButtonGroup> */}

            <ButtonGroup size="small" aria-label="small outlined button group" style={{ marginBottom: 15 }}>
                {billBoard.getSideA().map((side,i) => {
                    return (<Button  key={i} onClick={() => this.setSide(side)}>{side}</Button>);
                })}
            </ButtonGroup>
            <ButtonGroup size="small" aria-label="small outlined button group">
                {billBoard.getSideB().map((side,i) => {
                    return (<Button key={i} onClick={() => this.setSide(side)}>{side}</Button>);
                })}
            </ButtonGroup>
        </div>);
    }

    getDescriptionRow(billboard? : Billboard) {
        if (billboard && billboard.description)
            return (
                <Row>
                    {this.props.translate('BillboardDetailsDescription')}: {billboard.description}
                </Row>
            );
        return null;
    }

    boardEditChange = (event: any) => {
        this.setState({boardEdit: event.target.value});
        console.log(event.target.value);
    }

    handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
        let Properties = this.state.propertySet.Properties.map((prop:IProperty) => {
            if(event.target.name == prop.PropertyName){
                prop.SelectedValueId = event.target.value as number
            }
            return prop;
        });
        this.setState({
            propertySet:{
                ...this.state.propertySet,
                Properties
            }
        })
    }

    render() {
        const billboard = this.props.billboards.find((b) => b.id === this.state.billboardId);
        if (!billboard)
            return null;
        console.log(billboard.propertyValueSetId);
        const imgIndex = billboard.id % 3 + 1;
        const imgSrc = `${process.env.PUBLIC_URL}/assets/img/billboards/billboard${imgIndex}.jpg`;

        return (
            <Modal show={this.state.showModal} onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                        <Modal.Title>{billboard.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                {this.getSidesLinks(billboard)}
                            </Col>
                            <Col>
                                <Image src={imgSrc} fluid/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup className={`billboard-popup-list billboard-edit ${this.state.editToggle ? 'hide' : 'show'}`}>
                                        <ListGroup.Item active className="billboard-popup-list__item">
                                            {this.props.translate('BillboardDetails')}
                                            <Button style={{textAlign: 'right'}} variant="contained" color="primary" onClick={this.openEditForm}>Edit</Button>
                                        </ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsConstructionType')}</b> {billboard.constructionType}</ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsFormat')}</b> {billboard.format}</ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsDirection')}</b> {billboard.getWayBySide(this.state.selectedSide)}</ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsLight')}</b> {billboard.light ? "Да" : "Нет"}</ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsType')}</b> {billboard.type}</ListGroup.Item>
                                        <ListGroup.Item><b>{this.props.translate('BillboardDetailsGid')}</b> {billboard.GID}{this.state.selectedSide}</ListGroup.Item>
                                </ListGroup>
                                {this.state.loading?"Loading....":null}
                                {this.renderEditForm(billboard)}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    renderEditForm(billboard:Billboard){

        if(this.state.propertySet.Properties.length > 0 && !this.state.loading){
            let propertySet: IPropertySet = this.state.propertySet;
            //const classes = useStyles();
            return ( <Grid container className={`billboard-edit ${this.state.editToggle ? 'show' : 'hide'}`}>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            {propertySet.Properties.map((prop,i) => (
                                <FormControl key={i}  style={{width:'100%', margin: '15px 0'}}>
                                    <InputLabel htmlFor="age-helper">{prop.PropertyName}</InputLabel>
                                    <Select
                                        value={prop.SelectedValueId}
                                        onChange={this.handleChange}
                                        input={<Input name={prop.PropertyName} id={`id-${prop.PropertyId}`} />}
                                    >
                                    {prop.Values.map((option,i) => (
                                        <MenuItem key={i} value={option.ValueId}>{option.Value}</MenuItem>
                                    ))}
                                    </Select>
                                    
                              </FormControl>
                            ))}
                        
                        <Grid container justify="flex-end" className="btn-edit-group">
                            <Grid style={{margin: '15px 0'}}>
                                <Button className="btn-cencel" variant="contained" color="secondary" onClick={this.closeEditForm}>Cencel</Button>
                                <Button onClick={this.handleSubmitForm} variant="contained" color="primary">Save</Button>
                            </Grid>
                        </Grid>
                        </form>
                    </Grid>
            </Grid>)
        }
        return null;
    }

    getPropertyValue(property: IProperty):string{
        let selectedValue = property.Values.find((val) => val.ValueId == property.SelectedValueId);
        return selectedValue?selectedValue.Value:"";
    }
}
export default BillboardDetail;

//<SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />