import { GeoMark, PropertyValueSet, LatLong, PropertiesHelper, Translation, ClientInfo } from "../../WebServerApi/webServerTypes";

export enum DefaultProperties {
    NumberOfSeatsOnSideA = 'NumberOfSeatsOnSideA',
    NumberOfSeatsOnSideB = 'NumberOfSeatsOnSideB',
    Format = 'Format',
    WayA = 'WayA',
    WayB = 'WayB',
    Type = 'Type',
    ConstructionType = 'ConstructionType',
    Light = 'Light',
    WayA_OddNumbers = 'WayA_OddNumbers',
    WayB_OddNumbers = 'WayB_OddNumbers',
}

export class Billboard {
    constructor(id: number, gid: string, sideACount: number, sideBCount: number, isAOdd: boolean, isBOdd: boolean, format: string,
        type: string,
        light: boolean,
        constructionType: string,
        wayA: string,
        wayB: string,
        properties: PropertyValueSet,
        name: string,
        location: LatLong,
        description: string,
        propertyValueSetId:number) {
        this.id = id;
        this.GID = gid;
        this.sideACount = sideACount;
        this.sideBCount = sideBCount;
        this.isAOdd = isAOdd;
        this.isBOdd = isBOdd;
        this.format = format;
        this.type = type;
        this.light = light;
        this.constructionType = constructionType;
        this.wayA = wayA;
        this.wayB = wayB;
        this.properties = properties;
        this.name = name;
        this.location = location;
        this.description = description;
        this.propertyValueSetId = propertyValueSetId;
    }

    id: number;
    GID: string;
    sideACount: number;
    sideBCount: number;
    isAOdd: boolean;
    isBOdd: boolean;
    format: string;
    type: string;
    light: boolean;
    constructionType: string;
    wayA: string;
    wayB: string;
    properties: PropertyValueSet;
    name: string;
    location: LatLong;
    description: string;
    propertyValueSetId: number;

    getSideA():string[] {
        return this.getSide("A", this.sideACount, this.isAOdd);
    }
    getSideB():string[] {
        return this.getSide("B", this.sideBCount, this.isBOdd);
    }
    private getSide(letter:string, count:number, isOdd:boolean): string[] {
        let r = new Array<string>(count);
        let c: number = 1;
        for (var i = 0; i < count; i++) {
            r[i] = `${letter}${c}`;
            c = c + (isOdd ? 2 : 1);
        }
        return r;
    }
    getWayBySide(side:string): string {
        return side[0] === 'A' ? this.wayA : this.wayB;
    }

    static createBillBoard(geoMark: GeoMark, properties: PropertyValueSet): Billboard|null {
        if (!PropertiesHelper.arePropertiesValid(properties))
            return null;
        return new Billboard(geoMark.Id,
            geoMark.ObjectId,
            parseInt(PropertiesHelper.getPropertyValue(properties, DefaultProperties.NumberOfSeatsOnSideA)!),
            parseInt(PropertiesHelper.getPropertyValue(properties, DefaultProperties.NumberOfSeatsOnSideB)!),
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WayA_OddNumbers) === 'true',
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WayB_OddNumbers) === 'true',
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Format)!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Type)!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.Light) === 'true',
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.ConstructionType)!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WayA)!,
            PropertiesHelper.getPropertyValue(properties, DefaultProperties.WayB)!,
            properties, geoMark.Name, geoMark.Points[0], geoMark.Description,
            geoMark.PropertyValueSetId);
    }
}

export interface BillboardState {
    geomarks: GeoMark[];
    properties: PropertyValueSet[];
    billboards: Billboard[];
    isInitialized: boolean;
    isInitializing: boolean;
    translationsLoading: boolean;
    translationsLoaded: boolean;
    translations: Translation[];
    companies: ClientInfo[];
}

export interface BillboardPrice {
  installation : number;
  extraInstallation : number;
  print : number;
  price : number;
}