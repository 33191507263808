import {RemoteCommandJson} from './RemoteCommandJson';
import {RemoteCommandSynchronizerCollection} from './RemoteCommandSynchronizerCollection';
import ServerService from './ServerService';

class RemoteCommandHandler {

  public static Handle(commandJson: string) {
    console.log(commandJson);
      var command = (JSON.parse(commandJson) as RemoteCommandJson);
    if (command.IsResult) {
      RemoteCommandSynchronizerCollection.Stop(command.CommandId!, command);
      return;
    }
    RemoteCommandHandler.HandleCommand(command);
  }

  private static HandleCommand(command: RemoteCommandJson) {
    switch (command.Command) {
      case "Ping":
        RemoteCommandHandler.HandlePing(command);
        break;
      default:
        console.log("command handler not found : " + command.Command!);
    }
  }

  private static HandlePing(command: RemoteCommandJson) {
    ServerService.Ping();
  }

}
export default RemoteCommandHandler;