import List from './List';

export class RemoteParameterJson {
  public Name: string;
  public ValueJson: string;

  constructor(name: string, value: any) {
      this.Name = name;
      if (typeof value === "number" || typeof value === "string" || typeof value === "boolean")
          this.ValueJson = String(value);
      else
          this.ValueJson = JSON.stringify(value);
  }
}

export class RemoteCommandJson {
  public CommandId: string | null = null;
  public IsResult: boolean = false;

  //----- Response-----
  public Result: string | null= null;
  public Exception: string | null = null;
  public ExceptionKey: string | null = null;

  //----- Request-----
  public Command: string | null = null;
  public CountParameters: number | null = null;
  public Parameters: Array<RemoteParameterJson> = [];


  public static CreateForSend(command: string, parameters: Array<RemoteParameterJson>, commandId: string | null): RemoteCommandJson {

    var commandJson = new RemoteCommandJson();

    commandJson.CountParameters = parameters.length;
    commandJson.Command = command;
    commandJson.Parameters = parameters;

    commandJson.CommandId = commandId;

    return commandJson;
  }

  public static CreateForSendResult(commandId: string, result: any, exception: string, exceptionKey: string): RemoteCommandJson {
    var commandJson = new RemoteCommandJson();

    commandJson.CommandId = commandId;
    commandJson.Exception = exception;
    commandJson.ExceptionKey = exceptionKey;
    commandJson.IsResult = true;

    commandJson.Result = JSON.stringify(result);

    return commandJson;
  }

  public GetParameter(name: string): any {

    var param = new List(this.Parameters).FirstOrDefault(item => { return item.Name === name });

    return param ? JSON.parse(param.ValueJson) : null;
  }

  public GetResult(): any {
    return this.Result ? JSON.parse(this.Result) : null;
  }
}