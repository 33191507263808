import React from 'react';
import { connect } from 'react-redux';
import { RootState,  actions } from '../store';
import { withRouter, RouteComponentProps, Switch } from 'react-router';
import { Route, Redirect } from 'react-router';
import Map from "../components/Map";
import BillboardList from "../components/BillboardList";
import Menu from '../components/Menu';
import LoginPage from './LoginPage';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import SipPhone from '../components/SipPhone';
import 'react-toastify/dist/ReactToastify.css';
import { UserInfo } from "../store/user/types";
import { Translation } from "../WebServerApi/webServerTypes";
import CustomersList from '../components/CustomersList';
import Icon from '@material-ui/core/Icon';

type DispatchProps = {
    loadTranslations: () => Promise<void>;
    initializeData: (key:string) => Promise<void>;
    loginWithKey: (key: string) => Promise<void>;
    initializeSip: (login: string, pass: string, username: string) => Promise<void>;
}

type StateProps = {
    isAuthorized: boolean;
    isAuthorizing: boolean;
    lastError?: string;
    licenceKey: string|null;
    sipLogin?: string;
    sipPassword?: string;
    userInfo?: UserInfo;

    isInitialized: boolean;
    isInitializing: boolean;
    translationsLoading: boolean;
    translationsLoaded: boolean;
    translations: Translation[];

    isSipInitialized: boolean;
    isSipInitializing: boolean;
    phoneNumber: string;
    hasActiveCall: boolean;
    isEstablished: boolean;
    isIncoming: boolean;
    isMuted: boolean;
    lastSipError: string;
}

type IState = {
    isToggle: boolean;
}

type Props = RouteComponentProps<{}> & DispatchProps & StateProps & LocalizeContextProps;
class AppStack extends React.Component<Props, IState> {

    constructor(props: Props) {
        super(props);
        this.phoneClick = this.phoneClick.bind(this);
        this.state = {
            isToggle: false
        }
        let lang = localStorage.getItem("lang");
        if (lang === null)
            lang = "ru";
        this.props.initialize(({
            languages: [
                { name: "En", code: "en" },
                { name: "Ру", code: "ru" }
            ],
            translation: [],
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: lang
            }
        }) as any);
    }

    phoneClick() {
        this.setState(state => ({
          isToggle: !state.isToggle
        }));
    }

    addTranslations(lang:string) {
        let texts = this.props.translations.filter(tr => tr.Language === lang);
        let srt: { [id: string]: string; } = {};
        for (let i = 0; i < texts.length; i++)
            srt[texts[i].Key] = texts[i].Text;
        this.props.addTranslationForLanguage(srt, lang);
    }

    componentDidMount() {
        if (!this.props.isAuthorized && !this.props.isAuthorizing && this.props.licenceKey) {
            this.props.loginWithKey(this.props.licenceKey);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.translationsLoaded && !this.props.translationsLoading) {
            this.props.loadTranslations();
        }
        if (this.props.translationsLoaded && !prevProps.translationsLoaded) {
            this.addTranslations("ru");
            this.addTranslations("en");
        }
        if (!this.props.isSipInitialized && !this.props.isSipInitializing && this.props.sipLogin && this.props.sipPassword && !this.props.lastSipError && this.props.userInfo) {
            let sipLogin = this.props.sipLogin!;
            let sipPassword = this.props.sipPassword!;
            this.props.initializeSip(sipLogin, sipPassword, this.props.userInfo.username);
        }
        if (this.props.isAuthorized && !this.props.isInitialized && !this.props.isInitializing && this.props.licenceKey) {
            this.props.initializeData(this.props.licenceKey);
        }
        if (!this.props.isAuthorized && !this.props.isAuthorizing && this.props.licenceKey) {
            this.props.loginWithKey(this.props.licenceKey);
        }
        //if (this.props.hasActiveCall && this.props.isIncoming) {
        //    let incomingToastOptions = {
        //        position: "bottom-right",
        //        autoClose: false,
        //        hideProgressBar: false,
        //        closeOnClick: false,
        //        pauseOnHover: true,
        //        draggable: true,
        //        closeButton: false,
        //        className: 'black-background'
        //    };
        //    //@ts-ignore
        //    toast(<IncomingCallToast number='322'/>, incomingToastOptions);
        //}
    }
    

    render() {
        if (this.props.isInitializing || this.props.isAuthorizing || !this.props.translationsLoaded)
            return (<div className="loader"></div>);


        if (!this.props.isAuthorized)
        {
            return (
                <div id="app" className="wrapper">
                    <LoginPage/>
                </div>
            );
        }
        else
            return (
                <div id="app" className="wrapper">
                    <Menu />
                    <div id="content">
                        <Route exact path="/" render={() => <Redirect to="/map" />}/>
                        <Switch>
                            <Route path="/:tab(map)" component={Map} />
                            <Route path="/:tab(billboardList)" component={BillboardList}/>
                            <Route path="/:tab(customers)" component={CustomersList}/>
                        </Switch>
                        <div className={`call-phone is-animating ${this.state.isToggle ? 'call-close' : ''}`} onClick={this.phoneClick}></div>
                        <div className={`call-popup ${this.state.isToggle ? 'show' : 'hide'}`}>
                                <div className="call-popup__content">
                                    <Icon className="call-popup__close" onClick={this.phoneClick}>close</Icon>
                                    <SipPhone ></SipPhone>
                                </div>
                            <div className="call-popup__backdrop" onClick={this.phoneClick}></div>
                        </div>
                    </div>
                    {/* <SipPhone /> */}
                </div>
            );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.user.isAuthorized,
    isAuthorizing: state.user.isAuthorizing,
    lastError: state.user.lastError,
    licenceKey: state.user.licenceKey,
    sipLogin: state.user.sipLogin,
    sipPassword: state.user.sipPassword,
    userInfo: state.user.userInfo,

    isInitialized: state.billboards.isInitialized,
    isInitializing: state.billboards.isInitializing,
    translationsLoading: state.billboards.translationsLoading,
    translationsLoaded: state.billboards.translationsLoaded,
    translations: state.billboards.translations,

    isSipInitialized: state.sip.isInitialized,
    isSipInitializing: state.sip.isInitializing,
    phoneNumber: state.sip.phoneNumber,
    hasActiveCall: state.sip.hasActiveCall,
    isEstablished: state.sip.isEstablished,
    isIncoming: state.sip.isIncoming,
    isMuted: state.sip.isMuted,
    lastSipError: state.sip.lastError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
    initializeData: (key:string) => dispatch(actions.billboards.initializeActionCreator(key)),
    loginWithKey: (key: string) => dispatch(actions.user.authorizeWithKeyActionCreator(key)),
    loadTranslations: () => dispatch(actions.billboards.fetchTranslationsActionCreator()),
    initializeSip: (login:string, pass:string, username:string) => dispatch(actions.sip.initializeSipActionCreator(login, pass, username))
});

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AppStack)));

//export default withLocalize(AppStack);
