import React, { Dispatch } from "react";
import { Sip } from '../sip/sip';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getTranslate, Translate, TranslateFunction} from "react-localize-redux";
import { RootState, selectors, actions } from '../store';
import CallHistoryList from "./CallHistoryList";
import { CallInfo } from "../store/sip/types";

type DispatchProps = {
    changeSipState: (number: string, hasActiveCall: boolean, isEstablished: boolean, isIncoming: boolean, isMuted: boolean) => void,
    incomingCallAction: (number: string) => void,
}

type StateProps = {
    translate: TranslateFunction,
    // showControl: boolean,
    sipLogin: string | undefined,
    sipNumber: string | undefined,
    showFullMenu: boolean,
}

type Props = StateProps & DispatchProps;
type State = {
    phone:string;
}
class SipPhone extends React.Component<Props, State> {
    state = {
        phone: ''
    }
    nameInput: HTMLInputElement | null | undefined;

    componentDidMount() {
        var self = this;
        Sip.OnSipChanged.subscribe(() => {
            self.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.nameInput) {
            if (this.state.phone)
                //this.nameInput.select();
                this.nameInput.focus();
            else
                this.nameInput.focus();
        }
    }

    setPhoneControl(call: CallInfo) {
        this.setState({
            phone: call.number
        });
    }
    setPhoneEvent(evt: any) {
        this.setState({
            phone: evt.target.value
        });
    }

    createIncomingTest() {
        this.props.incomingCallAction("+79512345678");
    }

    callBlock() {
        if (Sip.HasActiveCall) {
            return null;
        }

        return (
            <div className="input-group">
                <input ref={(input: HTMLInputElement | null) => { this.nameInput = input; }} autoComplete="off" type="text" className="form-control" placeholder={this.props.translate("SipPhone") as string} value={this.state.phone} onChange={this.setPhoneEvent.bind(this)} />
                <div className="input-group-append">
                    <button className="btn btn-outline-success" type="button" onClick={() => {
                        Sip.Call(this.props.sipNumber!, this.state.phone);
                        this.setState({
                            phone: ''
                        });
                    }}><Translate id='SipCall'/></button>
                </div>
            </div>
            );
    }

    activeCallBlock() {
        if (!Sip.HasActiveCall) {
            return null;
        }
        /*
                    <a className='btn btn-outline-success' onClick={this.acceptCall.bind(this)}><img width="30" height="30" src='assets/img/call_accept.png'/></a>
                    <a className='btn btn-outline-danger' onClick={this.refuseCall.bind(this)}><img width="30" height="30" src='assets/img/call_refuse.png'/></a>*/
        //var answerButton = Sip.IsIncomingCall && !Sip.IsEstablished || true ? (<button className="btn btn-outline-success" type="button" onClick={() => Sip.Answer()}><Translate id='SipAnswer'/></button>) : null;
        //<button className="btn btn-outline-danger" type="button" onClick={() => Sip.HangUp()}><Translate id='SipHangUp' /></button>
        var answerButton = Sip.IsIncomingCall && !Sip.IsEstablished
            ? (<a href='#' className='btn btn-outline-success' style={{ padding: 3 }} onClick={() => Sip.Answer()
            }><img width="30" height="30" src='assets/img/call_accept.png' alt={this.props.translate('SipAnswer').toString()}/></a>)
            : null;

        var text = this.props.translate(Sip.IsIncomingCall ? "SipCallFrom" : "SipCallTo");
        if (Sip.IsEstablished)
            text = this.props.translate("SipInCallWith");

        return (
            <div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"> {text} </span>
                        <span className="input-group-text">{Sip.PhoneNumber}</span>
                    </div>
                    <div className="input-group-append" style={{height:38}} >
                        {answerButton}
                        <a href='#' className='btn btn-outline-danger' style={{ padding: 3 }} onClick={() => Sip.HangUp()}><img width="30" height="30" src='assets/img/call_refuse.png' alt={this.props.translate('SipHangUp').toString()} /></a>
                    </div>
                </div>
            </div>);
    }


    render() {
        if (!Sip.IsInitialized)
            return false;
        // if (!this.props.showControl && !Sip.HasActiveCall)
        //     return null;
        //<button className='btn btn-outline-info' type="button" onClick={this.createIncomingTest.bind(this)}>Test</button>
        return (
            <div>
                <div className="call-phone__number">
                    <span><Translate id='SipYourNumber' /></span> {this.props.sipNumber}
                </div>
                <div>
                    {this.callBlock()}

                    {this.activeCallBlock()}
                </div>
                <CallHistoryList onClick={this.setPhoneControl.bind(this)}/>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    translate: getTranslate(state.localize),
    showControl: selectors.app.showPhoneControl(state.app),
    sipLogin: state.user.sipLogin,
    sipNumber: selectors.user.sipNumber(state.user),
    showFullMenu: state.app.showFullMenu
});
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    changeSipState: (number: string, hasActiveCall: boolean, isEstablished: boolean, isIncoming: boolean, isMuted: boolean) => dispatch(actions.sip.sipStateChangedAction(number, hasActiveCall, isEstablished, isIncoming, isMuted)),
    incomingCallAction: (number:string) => dispatch(actions.sip.sipIncomingCallAction(number))
});
export default connect(mapStateToProps, mapDispatchToProps)(SipPhone);