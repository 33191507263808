import { combineReducers } from 'redux';

import billboards from './billboards/reducer';
import app from './app/reducer';
import sip from './sip/reducer';
import user from './user/reducer';
import customers from './customers/reducer';
import { localizeReducer } from "react-localize-redux";

const rootReducer = combineReducers({
    billboards,
    user,
    app,
    sip,
    customers,
    localize: localizeReducer,
});


export default rootReducer;