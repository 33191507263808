import { CustomerState, CustomerFilters } from './types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { WebServerProxy } from "../../WebServerApi/WebServerProxy";
import { ClientInfo } from "../../WebServerApi/webServerTypes";

export enum CustomerActionTypes {
    SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS',
    SEARCH_CUSTOMERS_ERROR = 'SEARCH_CUSTOMERS_ERROR',
    SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS',
    SAVE_CUSTOMER = 'SAVE_CUSTOMER',
    SAVE_CUSTOMER_ERROR = 'SAVE_CUSTOMER_ERROR',
    SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FILTERS = 'UPDATE_CUSTOMER_FILTERS',
    SEARCH_INN = 'SEARCH_INN',
    SEARCH_INN_ERROR = 'SEARCH_INN_ERROR',
    SEARCH_INN_SUCCESS = 'SEARCH_INN_SUCCESS',
}


export interface ISearchCustomersAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS> {
}
export interface ISearchCustomersErrorAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS_ERROR> {
    error: string,
}
export interface ISearchCustomersSuccessAction extends Action<CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS> {
    customers: ClientInfo[],
}
export interface ISaveCustomerAction extends Action<CustomerActionTypes.SAVE_CUSTOMER> {
}
export interface ISaveCustomerErrorAction extends Action<CustomerActionTypes.SAVE_CUSTOMER_ERROR> {
    error: string,
}
export interface ISaveCustomerSuccessAction extends Action<CustomerActionTypes.SAVE_CUSTOMER_SUCCESS> {
    customer: ClientInfo,
}
export interface IUpdateCustomerFilters extends Action<CustomerActionTypes.UPDATE_CUSTOMER_FILTERS> {
    filters: CustomerFilters,
}
export interface ISearchInnAction extends Action<CustomerActionTypes.SEARCH_INN> {
}
export interface ISearchInnErrorAction extends Action<CustomerActionTypes.SEARCH_INN_ERROR> {
    error: string,
}
export interface ISearchInnSuccessAction extends Action<CustomerActionTypes.SEARCH_INN_SUCCESS> {
    company: ClientInfo|null
}

export type CustomerActions =
    | ISearchCustomersAction
    | ISearchCustomersErrorAction
    | ISearchCustomersSuccessAction
    | ISearchInnAction
    | ISearchInnErrorAction
    | ISearchInnSuccessAction
    | ISaveCustomerAction
    | ISaveCustomerErrorAction
    | ISaveCustomerSuccessAction
    | IUpdateCustomerFilters;

export const searchCustomersActionCreator: ActionCreator<ThunkAction<Promise<any>, CustomerState, string, ISearchCustomersSuccessAction>> = (key:string, filter: CustomerFilters) => {
    return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
        try {
            const fetchingAction: ISearchCustomersAction = {
                type: CustomerActionTypes.SEARCH_CUSTOMERS
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(fetchingAction);
            WebServerProxy.getCustomers(key, filter).then(result => {
                const successAction: ISearchCustomersSuccessAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS,
                    customers: result
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(successAction);
            }).catch(e => {
                const failAction: ISearchCustomersErrorAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_ERROR,
                    error: e.message
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
            });
        } catch (e) {
            const failAction: ISearchCustomersErrorAction = {
                type: CustomerActionTypes.SEARCH_CUSTOMERS_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
        }
    }
}

export const saveCustomersActionCreator: ActionCreator<ThunkAction<Promise<any>, CustomerState, string, ISearchCustomersSuccessAction>> = (key: string, client: ClientInfo) => {
    return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
        try {
            const fetchingAction: ISaveCustomerAction = {
                type: CustomerActionTypes.SAVE_CUSTOMER
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(fetchingAction);
            WebServerProxy.saveClient(key, client).then(result => {
                const successAction: ISaveCustomerSuccessAction = {
                    type: CustomerActionTypes.SAVE_CUSTOMER_SUCCESS,
                    customer: client
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(successAction);
            }).catch(e => {
                const failAction: ISaveCustomerErrorAction = {
                    type: CustomerActionTypes.SAVE_CUSTOMER_ERROR,
                    error: e.message
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
            });
        } catch (e) {
            const failAction: ISaveCustomerErrorAction = {
                type: CustomerActionTypes.SAVE_CUSTOMER_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
        }
    }
}

export const searchInnActionCreator: ActionCreator<ThunkAction<Promise<any>, CustomerState, string, ISearchCustomersSuccessAction>> = (key: string, inn: string) => {
    return async (dispatch: ThunkDispatch<CustomerState, void, Action<any>>) => {
        try {
            let filter: CustomerFilters = {
                name: '',
                phone: '',
                email: '',
                inn: '',
                isLegal: null,
                isVip: null,
                companyId: 0,
                dateFrom: null,
                dateTo: null,
                ordersFrom: null,
                ordersTo: null,
            };
            filter.isLegal = true;
            filter.inn = inn;
            const fetchingAction: ISearchCustomersAction = {
                type: CustomerActionTypes.SEARCH_CUSTOMERS
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(fetchingAction);
            WebServerProxy.getCustomers(key, filter).then(result => {
                const successAction: ISearchCustomersSuccessAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_SUCCESS,
                    customers: result
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(successAction);
                const fetchingByInnAction: ISearchInnAction = {
                    type: CustomerActionTypes.SEARCH_INN
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(fetchingByInnAction);
                WebServerProxy.getClientByINN(inn).then(result => {
                    const successInnAction: ISearchInnSuccessAction = {
                        type: CustomerActionTypes.SEARCH_INN_SUCCESS,
                        company: result ? result : null
                    };
                    (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(successInnAction);
                }).catch(e => {
                    const failInnAction: ISearchInnErrorAction = {
                        type: CustomerActionTypes.SEARCH_INN_ERROR,
                        error: e.message
                    };
                    (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failInnAction);
                });
            }).catch(e => {
                const failAction: ISearchCustomersErrorAction = {
                    type: CustomerActionTypes.SEARCH_CUSTOMERS_ERROR,
                    error: e.message
                };
                (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
            });


        } catch (e) {
            const failAction: ISearchInnErrorAction = {
                type: CustomerActionTypes.SEARCH_INN_ERROR,
                error: e.message
            };
            (dispatch as ThunkDispatch<CustomerState, void, Action<any>>)(failAction);
        }
    }
}


export function updateCustomerFiltersAction(filter: CustomerFilters) {
    const action: IUpdateCustomerFilters = {
        type: CustomerActionTypes.UPDATE_CUSTOMER_FILTERS,
        filters: filter
    }
    return action;
}