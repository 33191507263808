import { Action, ActionCreator } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk/index";
import { SipState, CallInfo } from './types';
import { Sip } from "../../sip/sip";
import { Consts } from "../Consts";
import { toast } from "react-toastify";
import {getIncomingCallControl} from '../../components/IncomingCallToast';


export enum SipActionTypes {
    INITIALIZE_SIP = 'INITIALIZE_SIP',
    INITIALIZE_SIP_SUCCESS = 'INITIALIZE_SIP_SUCCESS',
    INITIALIZE_SIP_FAIL = 'INITIALIZE_SIP_FAIL',
    SIP_STATE_CHANGED = 'SIP_STATE_CHANGED',
    SIP_ADD_CALL_HISTORY = 'SIP_ADD_CALL_HISTORY',
    SIP_INCOMING_CALL = 'SIP_INCOMING_CALL',
}


export interface IInitializeSipAction extends Action<SipActionTypes.INITIALIZE_SIP> {
}
export interface IInitializeSipSuccessAction extends Action<SipActionTypes.INITIALIZE_SIP_SUCCESS> {
    login: string,
    username: string,
    calls: CallInfo[],
}
export interface IInitializeSipFailAction extends Action<SipActionTypes.INITIALIZE_SIP_FAIL> {
    error:string
}
export interface ISipStateChangedAction extends Action<SipActionTypes.SIP_STATE_CHANGED> {
    phoneNumber: string;
    hasActiveCall: boolean;
    isEstablished: boolean;
    isIncoming: boolean;
    isMuted: boolean;
}
export interface ISipIncomingCallAction extends Action<SipActionTypes.SIP_INCOMING_CALL> {
    phoneNumber: string;
}
export interface ISipAddCallHistoryAction extends Action<SipActionTypes.SIP_ADD_CALL_HISTORY> {
    call: CallInfo
}


export type SipActions =
    | IInitializeSipAction
    | IInitializeSipSuccessAction
    | ISipStateChangedAction
    | ISipAddCallHistoryAction
    | ISipIncomingCallAction
    | IInitializeSipFailAction;


export function sipAddCallHistoryAction(call: CallInfo) {
    const action: ISipAddCallHistoryAction = {
        type: SipActionTypes.SIP_ADD_CALL_HISTORY,
        call: call
    }
    return action;
}

export function sipStateChangedAction(phoneNumber: string, hasActiveCall: boolean, isEstablished: boolean, isIncoming: boolean, isMuted: boolean) {
    const action: ISipStateChangedAction = {
            type: SipActionTypes.SIP_STATE_CHANGED,
            phoneNumber: phoneNumber,
            hasActiveCall: hasActiveCall,
            isEstablished: isEstablished,
            isIncoming: isIncoming,
            isMuted: isMuted,
        }
    return action;
}

export function sipIncomingCallAction(phoneNumber: string) {
    return (dispatch: ThunkDispatch<SipState, void, Action<any>>) => {
        const action: ISipIncomingCallAction = {
            type: SipActionTypes.SIP_INCOMING_CALL,
            phoneNumber: phoneNumber
        }
        dispatch(action);
        let incomingToastOptions = {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            className: 'black-background'
        };
        //@ts-ignore
        toast(getIncomingCallControl(phoneNumber), incomingToastOptions);
        const historyAction: ISipAddCallHistoryAction = {
            type: SipActionTypes.SIP_ADD_CALL_HISTORY,
            call: new CallInfo(new Date(), phoneNumber, true, false),
        }
        dispatch(historyAction);
    }
}

export const initializeSipActionCreator: ActionCreator<ThunkAction<Promise<any>, SipState, string, IInitializeSipSuccessAction>> =
    (login: string, pass: string, username:string) => {
        return async (dispatch: ThunkDispatch<SipState, void, Action<any>>) => {
            const initAction: IInitializeSipAction = {
                type: SipActionTypes.INITIALIZE_SIP
            };
            (dispatch as ThunkDispatch<SipState, void, Action<any>>)(initAction);

            try {
                //Sip.Initialize("sip:100@sip.sedi.ru", "2302377b09a8605b5443306acae4841e", "wss://sip.sedi.ru:8089/ws");
                Sip.Initialize(login, pass, Consts.SipUri);
                let callHistoryJson = localStorage.getItem("calls_" + username);
                let callhistory: CallInfo[] = callHistoryJson === null ? [] : JSON.parse(callHistoryJson, Helper.CallInfoRevive);
                const successAction: IInitializeSipSuccessAction = {
                    type: SipActionTypes.INITIALIZE_SIP_SUCCESS,
                    login: login,
                    calls: callhistory,
                    username: username,
                };
                (dispatch as ThunkDispatch<SipState, void, Action<any>>)(successAction);
            } catch (e) {
                const failAction: IInitializeSipFailAction = {
                    type: SipActionTypes.INITIALIZE_SIP_FAIL,
                    error: e.message
                };
                (dispatch as ThunkDispatch<SipState, void, Action<any>>)(failAction);
            }
        }
    }

class Helper {
    public static CallInfoRevive(key: any, value: any): any {
        if (key === 'time') {
            return new Date(value);
        }

        return value;
    }
}