import { RemoteParameterJson, RemoteCommandJson } from "./RemoteCommandJson";
import List from "./List";
import { SignalR } from "./SignalR";
import { RemoteCommandCallbackData, RemoteCommandSynchronizerCollection, RemoteCommandSynchronizer } from
    "./RemoteCommandSynchronizerCollection";
import { WebServiceLoginResult } from "./ResultTypes";

export interface RemoteCommandCallback<ReturnType> {
    (result: RemoteCommandCallbackData<ReturnType>): void
}

class ServerProxyHelper<ReturnType> {
    private GetGuid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

    ExecuteCommandAsync(remoteCommand: string,
        parameters: Array<RemoteParameterJson>,
        callback: RemoteCommandCallback<ReturnType>) {

        let commandId: string | null = null;
        if (callback != null) {
            commandId = this.GetGuid();
            RemoteCommandSynchronizerCollection.Add(new RemoteCommandSynchronizer<ReturnType>(commandId, callback));
        }

        const command = RemoteCommandJson.CreateForSend(remoteCommand, parameters, commandId);
        const json = JSON.stringify(command);
        SignalR.SendCommand(json);
    }

    ExecuteCommandAsyncWithPromise(remoteCommand: string, parameters: Array<RemoteParameterJson>): Promise<ReturnType> {
        return new Promise(
            (resolve, reject) => {
                var commandId: string | null = null;
                commandId = this.GetGuid();
                RemoteCommandSynchronizerCollection.Add(new RemoteCommandSynchronizer<ReturnType>(commandId,
                    (result: RemoteCommandCallbackData<ReturnType>) => {
                        console.log(result);
                        if (result.Exception)
                            reject(new Error(result.Exception));
                        else {
                            if (result.IsCommandDelivered) {
                                resolve(result.Result);
                            } else
                                reject(new Error("Server is not available."));
                        }
                    }));
                var command = RemoteCommandJson.CreateForSend(remoteCommand, parameters, commandId);
                var json = JSON.stringify(command);
                SignalR.SendCommand(json);
            }
        );

    }
}

export class ServerProxy {
    static Ping(): Promise<number> {
        const list = new List<RemoteParameterJson>();
        list.Add(new RemoteParameterJson("millisecond", 54887));
        list.Add(new RemoteParameterJson("checkAuthorization", true));

        return (new ServerProxyHelper<number>().ExecuteCommandAsyncWithPromise("Ping", list.GetItems()));
    }

    static Login(username: string, password: string): Promise<WebServiceLoginResult> {
        const list = new List<RemoteParameterJson>();
        list.Add(new RemoteParameterJson("login", username));
        list.Add(new RemoteParameterJson("password", password));
        return (new ServerProxyHelper<WebServiceLoginResult>().ExecuteCommandAsyncWithPromise("Login", list.GetItems()));
    }

    static LoginWithLicenceKey(key: string): Promise<WebServiceLoginResult> {
        const list = new List<RemoteParameterJson>();
        list.Add(new RemoteParameterJson("key", key));
        return (new ServerProxyHelper<WebServiceLoginResult>().ExecuteCommandAsyncWithPromise("LoginWithLicenceKey", list.GetItems()));
    }
    static Logout(): Promise<void> {
        const list = new List<RemoteParameterJson>();
        return (new ServerProxyHelper<void>().ExecuteCommandAsyncWithPromise("Logout", list.GetItems()));
    }
    static SendActivationKey(phoneOrEmail: string): Promise<void> {
        const list = new List<RemoteParameterJson>();
        list.Add(new RemoteParameterJson("phoneOrEmail", phoneOrEmail));
        return (new ServerProxyHelper<void>().ExecuteCommandAsyncWithPromise("SendActivationKey", list.GetItems()));
    }
    static LoginWithActivationKey(activationKey: string): Promise<WebServiceLoginResult> {
        const list = new List<RemoteParameterJson>();
        list.Add(new RemoteParameterJson("activationKey", activationKey));
        return (new ServerProxyHelper<WebServiceLoginResult>().ExecuteCommandAsyncWithPromise("LoginWithActivationKey", list.GetItems()));
    }
}