import { GeoMark, PropertyValueSet, Translation, CustomerWebFilter, ClientInfo, IClientInfo, IPropertySet } from "./webServerTypes";
import { CustomerFilters } from "../store/customers/types";

interface IParametersDictionary {
    [index: string]: string;
}
export class WebServerProxy {
    
    private static getUri(method: string, params: IParametersDictionary): URL {
        let url = window.location.hostname === 'localhost'
            ? new URL(`https://test2.sedi.ru:9021/api/webclient/${method}`)
            : new URL(`https://${window.location.hostname}:9021/api/webclient/${method}`);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return url;
    }

    static async getBillboards(key: string): Promise<GeoMark[]> {
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("GetBillboards", arr).toString(),
            {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${key}`
                }
            });
        const result = await response.json();
        return result as GeoMark[];
    }

    static async getProperties(key: string, ids: number[]): Promise<PropertyValueSet[]> {
        let arr: IParametersDictionary = {};
        ids.forEach((value, index, array) => { arr[`propertyValueSetIds[${index}]`] = value.toString()});
        const response = await fetch(WebServerProxy.getUri("GetProperties", arr).toString(),
            {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${key}`
                }
            });
        const result = await response.json();
        return result as PropertyValueSet[];
    } 

    static async getAllCompanies(key: string): Promise<ClientInfo[]> {
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("GetAllCompanies", arr).toString(),
            {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${key}`
                }
            });
        const result = await response.json();
        return result as ClientInfo[];
    } 

    static async getTranslations(): Promise<Translation[]> {
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("GetLocalization", arr).toString(),
            {
                method: 'GET'
            });
        const result = await response.json();
        return result as Translation[];
    }

    static async getCustomers(key: string, filters: CustomerFilters): Promise<ClientInfo[]> {
        let arr: IParametersDictionary = {};
        let filter: CustomerWebFilter = {
            Name: filters.name,
            Phone: filters.phone,
            Email: filters.email,
            INN: filters.inn,
            CompanyId: filters.companyId,
            IsLegal: filters.isLegal,
            IsVip: filters.isVip,
            OrderFrom: filters.ordersFrom,
            OrderTo: filters.ordersTo,
            RegistrationDateFrom: filters.dateFrom,
            RegistrationDateTo: filters.dateTo,
        };
        const response = await fetch(WebServerProxy.getUri("GetCustomers", arr).toString(),
            {
                method: 'POST',
                body: JSON.stringify(filter),
                headers: {
                    'Authorization': `Basic ${key}`,
                    'Content-Type': "application/json;charset=UTF-8"
                }
            });
        const result = await response.json();
        return (result as IClientInfo[]).map(c => new ClientInfo(c.Id, c.Name, c.FirstName, c.SecondName, c.Patronymic, c.Phones, c.Emails, c.IsLegalEntity, c.INN, c.IsVip, c.OrdersCount, c.RegistrationDate));
    }


    static async saveClient(key: string, client: ClientInfo): Promise<void> {
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("SaveClient", arr).toString(),
            {
                method: 'POST',
                body: JSON.stringify(client),
                headers: {
                    'Authorization': `Basic ${key}`,
                    'Content-Type': "application/json;charset=UTF-8"
                }
            });
        const result = await response;
        if (result.status !== 200)
            throw "������ ��� ����������";
        return;
    }

    static async getClientByINN(inn: string): Promise<ClientInfo | undefined> {
        const response = await fetch('https://test2.sedi.ru/webapi?q=get_requisites&inn='+inn);
        const result = await response.json();
        let info = ClientInfo.fromInnData(result.Data.suggestions[0].data);
        return info;
    }

    static async getPropertySet(propertyValueSetId: number):Promise<IPropertySet>{
        let arr: IParametersDictionary = {"propertyValueSetId":`${propertyValueSetId}`};
        const response = await fetch(WebServerProxy.getUri("GetPropertySet", arr).toString(),
            {
                method: 'GET'
            });
        const result = await response.json();
        return result as IPropertySet;
    }

    static async savePropertySet(key: string,propertySet: IPropertySet):Promise<number>{
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("SavePropertySet", arr).toString(),
            {
                method: 'POST',
                body: JSON.stringify(propertySet),
                headers: {
                    'Authorization': `Basic ${key}`,
                    'Content-Type': "application/json;charset=UTF-8"
                }
            });
        const result = await response.json();
        console.log(result, 'savePropertySet()')
        if (result.status !== 200)
            throw "Error";
        return result.propertyValueSetId;
    }

    static async SetGeoMark(key: string, geomark: GeoMark): Promise<GeoMark> {
        let arr: IParametersDictionary = {};
        const response = await fetch(WebServerProxy.getUri("SetGeoMark", arr).toString(),
            {
                method: 'POST',
                body: JSON.stringify(geomark),
                headers: {
                    'Authorization': `Basic ${key}`,
                    'Content-Type': "application/json;charset=UTF-8"
                }
            });
        const result = await response.json();
        if (result.status !== 200)
            throw "Error SetGeoMark()";
        return result.geomark;
    }
}