import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { RootState, selectors, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { Form, InputGroup, Button, Col } from "react-bootstrap"
import ReactDataGrid from "react-data-grid";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { withLocalize, LocalizeContextProps, Translate, getTranslate, TranslateFunction } from "react-localize-redux";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./css/CustomersList.css";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, Action } from "redux";
import { CustomerFilters } from "../store/customers/types";
import { ClientInfo } from "../WebServerApi/webServerTypes";
import { ClientDetail, ConnectedClientDetail} from "../pages/ClientDetail";


registerLocale("ru", ru);

type DispatchProps = {
    updateFilterState: (filter: CustomerFilters) => void,
    searchClients: (key: string, filter: CustomerFilters) => void,
    searchByInn: (key: string, inn: string) => void,
}

type StateProps = {
    companies: ClientInfo[],
    translateFunc: TranslateFunction,
    filter: CustomerFilters,
    licenceKey: string | null,
    foundCustomers: ClientInfo[],
    isLoading: boolean,
    foundByInn: ClientInfo|null
}

type Props = RouteComponentProps<{}> & StateProps & LocalizeContextProps & DispatchProps;


enum LegalTypes {
    Physical,
    Legal,
    Both
}
enum VipTypes {
    Vip,
    NotVip,
    Both
}

const DateFormatter = (props:any) => (
    <>{props.value && props.value != '' && (new Date(props.value)).getFullYear() > 2000 ? ( new Date(props.value)).toLocaleDateString('ru') : ''}</>
);


class CustomersList extends React.Component<Props> {
    private modalForList: React.RefObject<ClientDetail> = React.createRef();

    constructor(props: Props) {
        super(props);
        //this.modalForList = React.createRef();
    }

    columns: any = [
        { key: "Id", name: "ID", resizable: true, width: 50 },
        { key: "Name", name: (<Translate id= 'CustomersListName'/>), resizable: true },
        { key: "PhonesString", name: (<Translate id='CustomersListPhone' />), resizable: true },
        { key: "EmailsString", name: (<Translate id='CustomersListEmail' />), resizable: true },
        { key: "INN", name: (<Translate id='CustomersListINN' />), resizable: true },
        //{ key: "IsVip", name: "IsVip", resizable: true, width: 50 },
        { key: "OrdersCount", name: (<Translate id='CustomersListOrdersCount' />), resizable: true, width: 70 },
        { key: "RegistrationDate", name: (<Translate id='CustomersListRegistrationDate' />), resizable: true, formatter: DateFormatter},
    ];

    navigateToclient(idx: number) {
        if (this.modalForList.current && idx >= 0) {
            this.modalForList.current.setClient(this.props.foundCustomers[idx].Id);
            this.modalForList.current.open();
        }
    };
    componentDidUpdate(prevProps: Props) {
        if (this.modalForList.current && !!this.props.foundByInn && prevProps.foundByInn == null) {
            this.modalForList.current.setByInn(this.props.foundByInn!, this.props.foundCustomers.length > 0 ? this.props.foundCustomers[0].Id : -1);
            this.modalForList.current.open();
        }
    }

    onlegalTypeChanged(type: LegalTypes) {
        let filter = { ...this.props.filter };
        filter.isLegal = type == LegalTypes.Both ? null : type == LegalTypes.Legal;
        if (filter.isLegal !== true)
            filter.inn = '';
        this.props.updateFilterState(filter);
    }

    onIsVipChanged(isVip: VipTypes) {
        let filter = { ...this.props.filter };
        filter.isVip = isVip == VipTypes.Both ? null : isVip == VipTypes.Vip;
        this.props.updateFilterState(filter);
    }

    onInnChanged(inn: string) {
        let filter = {...this.props.filter};
        filter.inn = inn;
        if (inn.length > 0)
            filter.isLegal = true;
        this.props.updateFilterState(filter);
    }

    onInnSearch() {
        this.props.searchByInn(this.props.licenceKey!, this.props.filter.inn);
    }

    onNameChanged(name: string) {
        let filter = {...this.props.filter};
        filter.name = name;
        this.props.updateFilterState(filter);
    }
    onPhoneChanged(phone: string) {
        let filter = { ...this.props.filter };
        filter.phone = phone;
        this.props.updateFilterState(filter);
    }
    onEmailChanged(email: string) {
        let filter = {...this.props.filter};
        filter.email = email;
        this.props.updateFilterState(filter);
    }
    onCompanyChanged(id: number) {
        let filter = {...this.props.filter};
        filter.companyId = id;
        this.props.updateFilterState(filter);
    }
    onDateFromChanged(date: Date|null) {
        let filter = {...this.props.filter};
        filter.dateFrom = date;
        this.props.updateFilterState(filter);
    }
    onDateToChanged(date: Date|null) {
        let filter = {...this.props.filter};
        filter.dateTo = date;
        this.props.updateFilterState(filter);
    }
    onOrdersFromChanged(ordersFromStr: string) {
        let ordersFrom: number | null = parseInt(ordersFromStr);
        if (ordersFromStr === '')
            ordersFrom = null;
        if (ordersFrom == null || !isNaN(ordersFrom) && ordersFrom >= 0) {
            let filter = { ...this.props.filter };
            filter.ordersFrom = ordersFrom;
            this.props.updateFilterState(filter);
        }
    }
    onOrdersToChanged(ordersToStr: string) {
        let ordersTo:number|null = parseInt(ordersToStr);
        if (ordersToStr === '')
            ordersTo = null;
        if (ordersTo == null || !isNaN(ordersTo) && ordersTo >= 0) {
            let filter = { ...this.props.filter };
            filter.ordersTo = ordersTo;
            this.props.updateFilterState(filter);
        }
    }
        

    getInnBlock() {
        if (this.props.filter.isLegal === false)
            return null;
        return (
            <Form.Group as={Col} controlId="customerSearchForm.INN">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><Translate id='CustomersListINN'/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e: any) => this.onInnChanged(e.target.value)} value={this.props.filter.inn}/>
                    <InputGroup.Append>
                        {this.props.isLoading
                            ?
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <Translate id='CustomersListLoading' />
                            </button>
                            : <Button onClick={this.onInnSearch.bind(this)}><Translate id='CustomersListSearch' /></Button>}
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>);
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="customerSearchForm.LegalSelect">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListClientType' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" onChange={(e: any) => this.onlegalTypeChanged(e.target.value)}>
                                    <option value={LegalTypes.Both} selected={this.props.filter.isLegal == null}>{this.props.translateFunc('CustomersListAll')}</option>
                                    <option value={LegalTypes.Legal} selected={this.props.filter.isLegal === true}>{this.props.translateFunc('CustomersListLegal')}</option>
                                    <option value={LegalTypes.Physical} selected={this.props.filter.isLegal === false}>{this.props.translateFunc('CustomersListNotLegal')}</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="customerSearchForm.Vip">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>ВИП статус</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" onChange={(e: any) =>this.onIsVipChanged(e.target.value)}>
                                    <option value={VipTypes.Both} selected={this.props.filter.isVip == null}>{this.props.translateFunc('CustomersListAll')}</option>
                                    <option value={VipTypes.Vip} selected={this.props.filter.isVip === true}>{this.props.translateFunc('CustomersListVipClients')}</option>
                                    <option value={VipTypes.NotVip} selected={this.props.filter.isVip === false}>{this.props.translateFunc('CustomersListNotVipClients')}</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                        {this.getInnBlock()}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="customerSearchForm.Name">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListName' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control onChange={(e: any) => this.onNameChanged(e.target.value)} value={this.props.filter.name}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="customerSearchForm.Phone">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListPhone' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control onChange={(e: any) => this.onPhoneChanged(e.target.value)} value={this.props.filter.phone}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="customerSearchForm.Email">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListEmail' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control onChange={(e: any) => this.onEmailChanged(e.target.value)} value={this.props.filter.email}/>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-4" controlId="customerSearchForm.CompanySelect">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListCompany' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" onChange={(e: any) => this.onCompanyChanged(e.target.value)}>
                                    <option value={0}></option>
                                    {this.props.companies.map(c => (<option value={c.Id}>#{c.Id} {c.Name}</option>))}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="customerSearchForm.OrdersCount">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListOrdersCount' /></InputGroup.Text>
                                    <InputGroup.Text><Translate id='CustomersListFrom' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control onChange={(e: any) => this.onOrdersFromChanged(e.target.value)} value={this.props.filter.ordersFrom == null ? '' : this.props.filter.ordersFrom.toString()}/>
                                <InputGroup.Append>
                                    <InputGroup.Text><Translate id='CustomersListTo' /></InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control onChange={(e: any) => this.onOrdersToChanged(e.target.value)} value={this.props.filter.ordersTo == null ? '' : this.props.filter.ordersTo.toString()}/>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="customerSearchForm.RegistrationDate">
                            <InputGroup className="date-group">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><Translate id='CustomersListRegistrationDate' /></InputGroup.Text>
                                    <InputGroup.Text><Translate id='CustomersListFrom' /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker dateFormat='dd/MM/yyyy' selected={this.props.filter.dateFrom} locale="ru" onChange={this.onDateFromChanged.bind(this)}/>
                                <InputGroup.Append>
                                    <InputGroup.Text><Translate id='CustomersListTo' /></InputGroup.Text>
                                    <DatePicker dateFormat='dd/MM/yyyy' selected={this.props.filter.dateTo} locale="ru" onChange={this.onDateToChanged.bind(this)} />
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="col-2 customer-search" controlId="customerSearchForm.RegistrationDate">
                            {this.props.isLoading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <Translate id='CustomersListLoading' />
                                </button>
                            :
                                <Button onClick={(e: any) => this.props.searchClients(String(this.props.licenceKey), this.props.filter)}><Translate id='CustomersListSearch' /></Button>
                            }
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br/>
                <div>
                    {(this.props.foundCustomers && this.props.foundCustomers.length > 0) ?
                        //@ts-ignore
                        (<ReactDataGrid columns={this.columns} rowGetter={(i: number) => this.props.foundCustomers[i]} rowsCount={this.props.foundCustomers.length} minHeight={500} onRowDoubleClick={this.navigateToclient.bind(this)}/>) : null}
                    
                </div>
                {
                    //@ts-ignore
                    (<ConnectedClientDetail ref={this.modalForList}/>)}
            </div>
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    translateFunc: getTranslate(state.localize),
    filter: state.customers.filters,
    licenceKey: state.user.licenceKey,
    foundCustomers: state.customers.foundCustomers,
    companies: state.billboards.companies,
    isLoading: state.customers.isLoading,
    foundByInn: state.customers.foundByInn
});
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
    updateFilterState: (filter: CustomerFilters) => dispatch(actions.customers.updateCustomerFiltersAction(filter)),
    searchClients: (key:string, filter: CustomerFilters) => dispatch(actions.customers.searchCustomersActionCreator(key, filter)),
    searchByInn: (key: string, inn: string) => dispatch(actions.customers.searchInnActionCreator(key, inn)),
});


export default withLocalize(withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(CustomersList)));